import DocumentMeta from 'react-document-meta';
import Styles from './styles.module.css';
// import Footer from '../../../tabs/footer/index';
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const SaveLabor = () => {

  const [ category, setCategory ] = useState('');
  const [ industry, setIndustry ] = useState('');
  const [ process, setProcess ] = useState('');
  const [ name, setName ] = useState('');
  const [ model, setModel ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ url, setUrl ] = useState('');
  const [ company, setCompany ] = useState("");
  const [ address, setAddress ] = useState('');
  const [ companyUrl, setCompanyUrl ] = useState("");

  var uid = uuid();

  var time = new Date();

  const [ modelList, setModelList ] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    firebase.firestore().collection("saveLabor").get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push(doc.data().model);
      });
      setModelList(tmp);
    });
    
  }, []);

  function push() {
    firebase.firestore().collection("saveLabor").doc(uid)
    .set({
      image: uid,
      category: category,
      industry: industry.split(','),
      process: process.split(','),
      name: name,
      description: description,
      model: model,
      url: url,
      company: company,
      address: address,
      companyUrl: companyUrl,
      time: time,
      ranking: 0,
      recommend: 0,
      view: 0,
    }).then(() => {
      setCategory('');
      setIndustry('');
      setProcess('');
      setName('');
      setModel('');
      setDescription('');
      setUrl('');
      setCompany('');
      setAddress('');
      setCompanyUrl('');
      window.location.reload();
    });
  }

  const styleImages = e => {
    // firebase.storage().ref().child(`/saveLabor/1873eb71-9cb7-4835-9967-b4429eccd19b`).put(e.target.files[0])
    if (e.target.files[0] !== null) {
      firebase.storage().ref().child(`/saveLabor/${uid}`).put(e.target.files[0]).then(() => {});
    }
  }

  const meta = {
    name: "robots",
    content: "noindex"
  };

  return (
    <>
    <DocumentMeta {...meta}>

      <div className={Styles.background}>

        <h4>申請機器の情報を入力してください。</h4>

        {modelList.includes(model) ?
          <div className={Styles.box}>
            <h4 style={{color : '#C52C20'}}>既に登録された製品を登録しようとしてるよー</h4>
          </div>
          :
          <></>
        }

        <div className={Styles.box}>

          <h4>カテゴリー</h4>
          <input className={Styles.input} placeholder={''} value={category} onChange={(e) => setCategory(e.target.value)} />

          <h4>業種</h4>
          <input className={Styles.input} placeholder={''} value={industry} onChange={(e) => setIndustry(e.target.value)} />

          <h4>業務領域</h4>
          <input className={Styles.input} placeholder={''} value={process} onChange={(e) => setProcess(e.target.value)} />

          <h4>設備の写真</h4>
          <input type="file" onChange={styleImages} />

          <h4>名前</h4>
          <input className={Styles.input} placeholder={''} value={name} onChange={(e) => setName(e.target.value)} />

          <h4>型番</h4>
          <input className={Styles.input} placeholder={''} value={model} onChange={(e) => setModel(e.target.value)} />

          <h4>製品の概要説明</h4>
          <textarea className={Styles.textarea} value={description} onChange={(e) => setDescription(e.target.value)} />

          <h4>製品のURL</h4>
          <input className={Styles.input} placeholder={''} value={url} onChange={(e) => setUrl(e.target.value)} />

          <h4>製造事業者名</h4>
          <input className={Styles.input} placeholder={''} value={company} onChange={(e) => setCompany(e.target.value)} />

          <h4>製造事業者の所在地</h4>
          <input className={Styles.input} placeholder={''} value={address} onChange={(e) => setAddress(e.target.value)} />

          <h4>製造事業者のURL</h4>
          <input className={Styles.input} placeholder={''} value={companyUrl} onChange={(e) => setCompanyUrl(e.target.value)} />

          <button onClick={() => push()}>設備を追加</button>

        </div>

      </div>

    </DocumentMeta>
    </>  );
}

export default SaveLabor;
