import React, { useState, useEffect } from 'react';
import Styles from'./styles.module.css';
import DefaultImage from './../../../image/DefaultImage.png';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/css';
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';

const SaveLabor = () => {

  const [ list1, setList1 ] = useState([]);
  const [ list2, setList2 ] = useState([]);
  const [ list3, setList3 ] = useState([]);

  useEffect(() => {

    firebase.firestore().collection("saveLabor").where('category', '==', 'スチームコンベクションオーブン').orderBy('ranking', 'asc').limit(3).get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        console.log(doc.id)
        tmp.push({...doc.data(), id: doc.id });
      });
      setList1(tmp);
    });

    firebase.firestore().collection("saveLabor").where('category', '==', '券売機').orderBy('ranking', 'asc').limit(3).get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        console.log(doc.id)
        tmp.push({...doc.data(), id: doc.id });
      });
      setList2(tmp);
    });

    firebase.firestore().collection("saveLabor").where('category', '==', '自動配膳ロボット').orderBy('ranking', 'asc').limit(3).get()
    .then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        console.log(doc.id)
        tmp.push({...doc.data(), id: doc.id });
      });
      setList3(tmp);
    });

  }, []);

  return (
    <>
    <div className={Styles.ranking}>
      <h4>ランキング</h4>
      <div className={Styles.ranking_lists}>
        <Splide
          options={{
            type: 'loop',
            perPage: 3,
            perMove: 1,
            gap: 20,
            breakpoints: {
              1200: {
                perPage: 2,
              },
              800: {
                perPage: 1,
              },
            }
          }}
        >
          <SplideSlide>
            <div className={Styles.ranking_box}>
              <h4>スチコン</h4>
              {list1.map((data, index) =>
                <Link to={`/product/id=${data.id}`}>
                  <div className={Styles.ranking_box_item}>
                    <h5 style={ index + 1 == 1 ? {color : '#DAA000'} : index + 1 == 2 ? {color : '#555555'} : {color : '#885400'}}>{index + 1}</h5>
                    <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data.image}?alt=media&token=`} 
                      onError={e => {
                        e.target.onError = null;
                        e.target.src = DefaultImage;
                      }}/>
                    <div>
                      <p>{data.company}</p>
                      <h5>{data.name}</h5>
                    </div>
                  </div>
                </Link>
              )}
              <Link to={`/search/industry=+works=+keyWord=スチームコンベクションオーブン+page=1`}>
                <h5>その他のスチコン</h5>
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className={Styles.ranking_box}>
              <h4>券売機</h4>
              {list2.map((data, index) =>
                <Link to={`/product/id=${data.id}`}>
                  <div className={Styles.ranking_box_item}>
                    <h5 style={{color : '#DAA000'}}>1</h5>
                    <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data.image}?alt=media&token=`} 
                      onError={e => {
                        e.target.onError = null;
                        e.target.src = DefaultImage;
                      }}/>
                    <div>
                      <p>{data.company}</p>
                      <h5>{data.name}</h5>
                    </div>
                  </div>
                </Link>
              )}
              <Link to={`/search/industry=+works=+keyWord=券売機+page=1`}>
                <h5>その他の券売機</h5>
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className={Styles.ranking_box}>
              <h4>自動配膳ロボット</h4>
              {list3.map((data, index) =>
                <Link to={`/product/id=${data.id}`}>
                  <div className={Styles.ranking_box_item}>
                    <h5 style={{color : '#DAA000'}}>1</h5>
                    <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data.image}?alt=media&token=`} 
                      onError={e => {
                        e.target.onError = null;
                        e.target.src = DefaultImage;
                      }}/>
                    <div>
                      <p>{data.company}</p>
                      <h5>{data.name}</h5>
                    </div>
                  </div>
                </Link>
              )}
              <Link to={`/search/industry=+works=+keyWord=自動配膳ロボット+page=1`}>
                <h5>その他の自動配膳ロボット</h5>
              </Link>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
    </>
  );
}

export default SaveLabor;
