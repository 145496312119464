import Styles from'./styles.module.css';
import Image1 from "./../../../../image/saveLabor/work/1.svg";
import Image2 from "./../../../../image/saveLabor/work/2.svg";
import Image3 from "./../../../../image/saveLabor/work/3.svg";
import { useHistory } from 'react-router-dom';

const SaveLabor = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.catalog_all}>
      <div className={Styles.catalog_1} onClick={() => history.push(`/search/industry=共通+works=施設管理+keyWord=+page=1`)}>
        <img src={Image1} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 施設管理' />
        <h4>施設管理</h4>
      </div>
      <div className={Styles.catalog_2} onClick={() => history.push(`/search/industry=共通+works=人事・労務管理+keyWord=+page=1`)}>
        <img src={Image2} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 人事・労務管理' />
        <h4>人事・労務管理</h4>
      </div>
      <div className={Styles.catalog_3} onClick={() => history.push(`/search/industry=共通+works=財務・経理+keyWord=+page=1`)}>
        <img src={Image3} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 財務・経理' />
        <h4>財務・経理</h4>
      </div>
    </div>
    </>
  );
}

export default SaveLabor;
