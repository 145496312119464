import './styles.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  
  return (
    <>
    <div className='footer'>
      <Link to='/'>trankllc.com</Link>
    </div>
    </>
  );
}

export default Footer;
