import Styles from'./styles.module.css';
import Worry1 from './../../../image/saveLabor/what/1.png';
import Worry2 from './../../../image/saveLabor/what/2.png';
import Worry3 from './../../../image/saveLabor/what/3.png';

const SaveLabor = () => {

  return (
    <>
    <div className={Styles.site_desc_point}>
      <h4>このサービスの特徴</h4>
      <p>このサービスは中小企業省力化投資補助金に掲載されている設備・機器を検索できるサイトです。<br />その他、設備・機器の導入に関するご相談もお受け付けしておりますので、技気軽にご連絡ください。</p>
      <div>
        <div>
          <div>
            <h4 style={{color : '#279A30'}}>01</h4>
            <p>補助金で設備を購入したい</p>
          </div>
          <p>弊社でお客さまが導入したい設備を検索し、申請、導入まで、サポートいたします。</p>
          <img src={Worry1} alt='補助金で設備を購入したい' />
        </div>
        <div>
          <div>
            <h4 style={{color : '#FCDC4A'}}>02</h4>
            <p>どの設備が購入可能か分からない</p>
          </div>
          <p>本サービスでは登録されている設備情報を一覧で確認する事ができます。</p>
          <img src={Worry2} alt='どの設備が購入可能か分からない' />
        </div>
        <div>
          <div>
            <h4 style={{color : '#2E81B0'}}>03</h4>
            <p>設備の導入手続きを依頼したい</p>
          </div>
          <p>補助金に関する知識がなく、設備・機器の導入方法が分からない方へのサポートを行います。</p>
          <img src={Worry3} alt='設備の導入手続きを依頼したい' />
        </div>
      </div>
      <button>当社に相談する</button>
    </div>
    </>
  );
}

export default SaveLabor;
