import DocumentMeta from 'react-document-meta';
import Styles from './../styles.module.css';
import Nav from "./../nav/index.js";
import TopImage from "./../../../image/media/0001/top.png";
import Footer from './../footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"
import { useEffect } from "react";

const BlogText0001 = () => {

  const meta = {
    title: '【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！ ',
    description: '【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！本記事では2024年度新設の目玉となる「中小企業省力化投資補助金」の申請をご検討されている事業者様に向けて、満たす必要がある要件を記載しております。省力化製品の導入に加え、事業者要件５つと事業要件７つの計12要件を満たすことで申請が可能となりますので、申請を検討している方は是非ご覧ください。',
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <>
          <p className={Styles.media_date}>2024/05/03 更新日: 2024/06/07</p>
          <h1 className={Styles.media_title}>【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！ </h1>
          <img src={TopImage} className={Styles.media_title_img} alt='事業再構築補助金' />
          </>

          <>
          <p className={Styles.media_text_text}>
            <br />
            本記事では2024年度新設の目玉となる「中小企業省力化投資補助金」の申請をご検討されている事業者様に向けて、<strong>"中小企業省力化投資補助金"の申請で満たす必要がある要件</strong>を記載しております。
            <br />
            <br />
            中小企業省力化投資補助金は、<strong>省力化製品の導入に加え、事業者要件５つと事業要件７つの計12要件を満たすことで申請が可能となります</strong>ので、申請を検討している方は是非ご覧ください。<br />
          </p>
          </>

          <>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>中小企業省力化投資補助金で対象となる「事業者」「事業」の要件</span></li>
              <li><span>申請前に満たさなければならない要件５つ</span></li>
              <li><span>事業計画で満たさなければならない要件７つ</span></li>
            </ul>
          </div>
          </>

          <p className={Styles.media_text_text}>
            こんにちは。<strong>補助金の申請サポートをしているトランク合同会社</strong>です。<br />
            <br />
            本記事では、中小企業省力化投資補助金の申請にあたり必要な要件をまとめています。<br />
            <strong>「中小企業省力化投資補助金の申請資格があるかを知りたい」という事業者様に向けて執筆させていただきます</strong>のでぜひ参考にしていただきたいと思います。<br />
          </p>

          <>
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>中小企業省力化投資補助金とは</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>中小企業省力化投資補助金申請の流れ</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>補助対象事業者であるかどうか</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>補助対象事業であるかの確認</strong></AnchorLink></li>
                <li><AnchorLink href="#5" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className={Styles.media_text_title}>
            <h2>1.中小企業省力化投資補助金とは</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            中小企業省力化投資補助金（通称：省力化補助金）は、以下のような補助金です。
          </p>
          <div className={Styles.media_introduction}>
            <p>
              <strong>中小企業省力化投資補助金とは？</strong><br />
              「企業等の売上拡大や生産性向上を後押しするため、人手不足に悩む中小企業等に対して、IoT、ロボット等の人手不足解消に効果がある汎用製品を導入するための事業費等の経費の一部を補助することにより、簡易で即効性がある省力化投資を促進し、中小企業等の付加価値額や生産性向上を図るとともに、賃上げにつなげることを目的」
            </p>
          </div>
          <p className={Styles.media_text_text}>
            つまり、<strong>中小企業が人手不足の解消のために省力化製品を導入することを支援する補助金</strong>であり、結果的に生産性向上や賃上げなどに繋げていくことが目的となる制度です。<br />
            <br />
            参照：<a href='https://shoryokuka.smrj.go.jp/' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金のホームページ|中小企業庁
            </a><br />
          </p>
          
          </>

          <>
          <div id='2' className={Styles.media_text_title}>
            <h2>2.中小企業省力化投資補助金申請の流れ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            「中小企業省力化補助金を申請したい」という方は、まず<strong>申請基準を満たすか</strong>を最初に確認する必要があります。<br />
            <br />
            そこで、下記の手順で申請に向けて準備する方法が最も簡単で分かりやすいのでおすすめです。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ① 補助対象事業者であるかどうか<br />
              ② 補助対象事業であるかの確認<br />
            </p>
          </div>
          </>

          <>
          <div id='3' className={Styles.media_text_title}>
            <h2>3.補助対象事業者であるかどうか</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            中小企業省力化投資補助金で対象となる事業者は下記５点を申請時点で満たす必要があります。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ① 国内で事業を営んでいること<br />
              ② 中小企業等であること<br />
              ③ 人手不足の状態にある<br />
              ④ 全従業員が最低賃金を超えている<br />
              ⑤ 補助金等の重複がない<br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>①日本国内で事業を営んでいる</h3><br />
          <p className={Styles.media_text_text}>
            日本国内で法人登記がされ、日本国内で事業を営んでいる必要があります。
          </p>
          <h3 className={Styles.media_text_text_title}>②中小企業等である</h3><br />
          <p className={Styles.media_text_text}>
            省力化投資補助金の申請をご検討されている方は、<strong>中小企業等に含まれている必要があります</strong>。<br />
            業種によって中小企業の定義は変化しますので、下表にてご自分の業種で該当するかをご確認ください。<br />
            ※資本金と従業員数のどちらかが規定の数字以下を満たしていれば中小企業という定義になります。<br />
          </p>
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th className={Styles.media_table_0001_1_1}>業種</th>
                <th className={Styles.media_table_0001_1_2}>資本金</th>
                <th className={Styles.media_table_0001_1_3}>従業員（常勤）</th>
              </tr>
              <tr>
                <td><strong>製造業、建設業、運輸業</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
              <tr>
                <td><strong>卸売業</strong></td>
                <td>1億円以下</td>
                <td>100人以下</td>
              </tr>
              <tr>
                <td><strong>サービス業</strong></td>
                <td>5,000万円以下</td>
                <td>100人以下</td>
              </tr>
              <tr>
                <td><strong>小売業</strong></td>
                <td>5,000万円以下</td>
                <td>50人以下</td>
              </tr>
              <tr>
                <td><strong>ゴム製品製造業</strong></td>
                <td>3億円以下</td>
                <td>900人以下</td>
              </tr>
              <tr>
                <td><strong>ソフトウエア業、または情報処理サービス業</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
              <tr>
                <td><strong>旅館業</strong></td>
                <td>5,000万円以下</td>
                <td>200人以下</td>
              </tr>
              <tr>
                <td><strong>その他の業種</strong></td>
                <td>3億円以下</td>
                <td>300人以下</td>
              </tr>
            </tbody>
          </table>
          <p className={Styles.media_text_text}>
            引用：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/application_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金公募要領2－3（１）より抜粋
            </a><br />
          </p>
          <p className={Styles.media_text_text}>
            <br />
            上表を満たされていない方でも、<strong>下記に該当される事業者様であれば申請可能です</strong>。<br />
            ※詳細に関しては中小企業省力化投資補助事業 コールセンター（0570-099-660）までご連絡ください。<br />
            <br />
            ①企業組合<br />
            ②協業組合<br />
            ③事業協同組合、事業協同小組合、協同組合連合会<br />
            ④商工組合、商工組合連合会<br />
            ⑤商店街振興組合、商店街振興組合連合会<br />
            ⑥水産加工業協同組合、水産加工業協同組合連合会<br />
            ⑦生活衛生同業組合、生活衛生同業小組合、生活衛生同業組合連合会 ⑧酒造組合、酒造組合連合会、酒造組合中央会、酒販組合、酒販組合連合会、酒販組合中央会<br />
            ⑨内航海運組合、内航海運組合連合会<br />
            ⑩技術研究組合<br />
            ⑪特定非営利活動法人（NPO 法人）<br />
            ※下記5点を満たす必要があります。<br />
            1）広く中小企業一般の振興・発展に直結し得る活動を行う特定非営利活動法人であること。<br />
            2）従業員数が３００人以下であること。<br />
            3）法人税法上の収益事業を行う特定非営利活動法人であること。<br />
            4）認定特定非営利活動法人ではないこと。<br />
            5）交付決定時までに補助金の事業に係る経営力向上計画の認定を受けていること。<br />
            ⑫社会福祉法人<br />
            ※下記3点を満たす必要があります。<br />
            1）「社会福祉法」第３２条に規定する所管庁の認可を受け設立されている法人であること。<br />
            2）従業員数が３００人以下であること。<br />
            3）収益事業の範囲内で補助事業を行うこと。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③人手不足の状態にある</h3><br />
          <p className={Styles.media_text_text}>
            <strong>人手不足であると認定されるのは下記4点のいずれかに該当する必要があります。</strong>
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ① 直近の従業員の平均残業時間が30時間を超えている<br />
              ② 整理解雇ではない離職退職で従業員が前年度比で5％以上減少している<br />
              ③ 求人を掲載したが充足には至らなかった<br />
              ④ その他、省力化を推し進める必要に迫られている<br />
            </p>
          </div>
          <p className={Styles.media_text_text}>
            いずれかから1つ以上を選択し、事業計画の中で説明する必要があり、④のみを選択した場合より詳細な事業計画の作成が必要です。<br />
            <br />
            詳しくは<a href='https://shoryokuka.smrj.go.jp/assets/pdf/application_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金公募要領3－2（２）
            </a>をご覧ください。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>④全従業員が最低賃金を超えている</h3><br />
          <p className={Styles.media_text_text}>
            申請時点で全ての従業員の賃金が最低賃金を超えている必要があります。最低賃金は地域によって異なります。<br />
            <br />
            詳しくは<a href='https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/koyou_roudou/roudoukijun/minimumichiran/' target="_blank" rel="nofollow noopener noreferrer">
            厚生労働省ホームページにて地域別最低賃金額
            </a>を参照してください。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑤補助金等の重複がないこと</h3><br />
          <p className={Styles.media_text_text}>
            <strong>下記リストに該当する方は補助金の重複扱いとなり、申請ができません。</strong><br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ・中小企業省力化投資補助金の交付決定を受けた<br />
              ・別の補助金で同一の経費を計上している<br />
              ・ものづくり補助金で2回以上交付決定を受けた<br />
              ・事業再構築補助金に採択された<br />
              ・観光地・観光産業における人材不足対策事業の交付決定もしくは申請している<br />
              ・二重受給となる<br />
              ・中小企業省力化投資補助金における「製造事業者」「販売事業者」に該当する<br />
            </p>
          </div>
          </>

          <>
          <div id='4' className={Styles.media_text_title}>
            <h2>4.補助対象事業であるかの確認</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            中小企業省力化投資補助金で対象となる事業をしなければ、要件を満たせませんので、<strong>検討している事業が「事業計画の内容」「申請する設備」の観点から対応しているか</strong>を確認してみましょう。<br />
          </p>
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>事業計画の内容</strong></th>
                <td>
                  ① 労働生産性の向上目標の設定・取組である<br />
                  ② カタログ価格以内の経費を組み込んでいるか<br />
                  ③ 従業員の解雇を積極的に行わないこと
                </td>
              </tr>
              <tr>
                <th><strong>申請する設備</strong></th>
                <td>
                  ④導入設備の対象業種・プロセスに該当するか<br />
                  ⑤保険に加入しているか<br />
                  ⑤既に保有する製品の置き換えでないこと
                </td>
              </tr>
              <tr>
                <th><strong>（上限引き上げ希望の方のみ）</strong></th>
                <td>⑦賃上げ目標を設定・表明し、実現に向けて取り組んでいる</td>
              </tr>
            </tbody>
          </table>
          <h3 className={Styles.media_text_text_title}>①労働生産性の向上目標を設定し、その実現に向けて取り組んでいるか</h3><br />
          <p className={Styles.media_text_text}>
            補助事業が終了して１～３年の間、労働生産性を年平均３％以上向上させる、かつその実現に向けた事業であることが必要です。<br />
            <br />
            <strong>労働生産性は以下のように計算されます。</strong>
          </p>
          <div className={Styles.media_introduction}>
            <p>
              労働生産性＝付加価値額÷従業員数<br />
              付加価値額＝営業利益＋人件費＋減価償却費<br />
            </p>
          </div>
          <p className={Styles.media_text_text}>
            細かい計算方法を知りたい方は以下をご参照ください。<br />
            参照：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/application_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金公募要領2－1（２）①
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>②カタログ価格以内の経費を組み込んでいるか</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金で申請する<strong>省力化製品にかかる価格・経費を補助対象として事業計画に組み込む必要があります</strong>。<br />
            <br />
            省力化製品にかかる金額はカタログに登録されている価格以内とする必要があります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③従業員の解雇を積極的に行わないこと</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金は「人員不足解消」が目的であるために、<strong>人員整理・解雇を行うと補助金の返還または収益納付が発生する可能性があります</strong>。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>④導入設備の対象業種・プロセスに該当するか</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金を活用して取り組む事業が、<strong>省力化製品で想定している業種やプロセスに当てはまる必要があります</strong>。<br />
            <br />
            省力化製品は業種が登録されており、異なる用途で使用する事業は対象となりませんので、下記リンクより省力化製品を検索いただき、各製品ページ内の「業種」「対応プロセス」に該当するかを確認してください。<br />
            参照：<a href='https://shoryokuka.com' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金 登録設備検索サイト
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑤保険に加入していること</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金の補助金額が500万円以上の場合火災等が原因の損失に備え付保割合が2分の1以上の保険または共済への加入が必要です。<br />
            <br />
            補助額が500万円に満たない場合もリスクヘッジとして加入が推奨されています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑥既に所有する製品の置き換えでないこと</h3><br />
          <p className={Styles.media_text_text}>
            こちらは当該補助金の目的である「人手不足」に沿わないためです。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑦賃上げ目標を設定・表明し、実現に向けて取り組んでいる</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金では賃金の引上げをすることで補助上限金額を引き上げることができますが、そのためには下記を満たす必要があります。
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ・下記2点を満たす事業計画の策定<br />
              （a）事業内最低賃金を45円以上増加させること<br />
              （b）給与支給総額を6％以上増加させること<br />
              ・事業計画を従業員に表明<br />
              ・実現のために取り組んでいること<br />
            </p>
          </div>
          </>

          <>
          <div id='5' className={Styles.media_text_title}>
            <h2>5.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            ここまで本記事を見てくださり、誠にありがとうございます！<br />
            本記事では中小企業省力化投資補助金の申請をご検討されている事業者様に向けて、<strong>申請時に満たさなければならない要件を中心に展開してきました</strong>。<br />
            しかしながら、初めて申請される方は一度読んだだけでは難しく、申請に向けて時間が取れない方も多くいらっしゃるかと思いますので、<strong>その際は、是非専門コンサルタントまでご相談ください。</strong><br />
            <br />
            また、中小企業省力化投資補助金のみならず、補助金の活用を通じて貴社の生産性向上・販路拡大に全力でサポートいたしますので、下記フォームよりお気軽にご連絡くださいませ。<br />
          </p>
          </>
          
        </div>
        
        {/* <Topic /> */}
        <div></div>
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
