import Styles from'./styles.module.css';
import { useHistory } from 'react-router-dom';

const SaveLabor = () => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.search_all}>
      <h4>カテゴリー</h4>
      <div>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=清掃ロボット+page=1`)} >清掃ロボット</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=配膳ロボット+page=1`)}>配膳ロボット</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=自動倉庫+page=1`)}>自動倉庫</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=検品・仕分システム+page=1`)}>検品・仕分システム</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=無人搬送車（AGV・AMR）+page=1`)}>無人搬送車（AGV・AMR）</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=スチームコンベクションオーブン+page=1`)}>スチームコンベクションオーブン</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=券売機+page=1`)}>券売機</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=自動チェックイン機+page=1`)}>自動チェックイン機</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=自動精算機+page=1`)}>自動精算機</p>
      </div>

      <h4>業務領域</h4>
      <div>
        <p onClick={() => history.push(`/search/industry=+works=施設管理+keyWord=+page=1`)}>施設管理</p>
        <p onClick={() => history.push(`/search/industry=+works=人事・労務管理+keyWord=+page=1`)}>人事・労務管理</p>
        <p onClick={() => history.push(`/search/industry=+works=財務・経理+keyWord=+page=1`)}>財務・経理</p>
        <p onClick={() => history.push(`/search/industry=+works=見積・契約+keyWord=+page=1`)}>見積・契約</p>
        <p onClick={() => history.push(`/search/industry=+works=資材調達+keyWord=+page=1`)}>資材調達</p>
        <p onClick={() => history.push(`/search/industry=+works=調査・測量+keyWord=+page=1`)}>調査・測量</p>
        <p onClick={() => history.push(`/search/industry=+works=施工+keyWord=+page=1`)}>施工</p>
        <p onClick={() => history.push(`/search/industry=+works=引渡+keyWord=+page=1`)}>引渡</p>
        <p onClick={() => history.push(`/search/industry=+works=加工・生産+keyWord=+page=1`)}>加工・生産</p>
        <p onClick={() => history.push(`/search/industry=+works=検査+keyWord=+page=1`)}>検査</p>
        <p onClick={() => history.push(`/search/industry=+works=販売・納品+keyWord=+page=1`)}>販売・納品</p>
        <p onClick={() => history.push(`/search/industry=+works=出荷+keyWord=+page=1`)}>出荷</p>
        <p onClick={() => history.push(`/search/industry=+works=入出庫+keyWord=+page=1`)}>入出庫</p>
        <p onClick={() => history.push(`/search/industry=+works=梱包・加工+keyWord=+page=1`)}>梱包・加工</p>
        <p onClick={() => history.push(`/search/industry=+works=請求・支払+keyWord=+page=1`)}>請求・支払</p>
        <p onClick={() => history.push(`/search/industry=+works=保管・在庫管理+keyWord=+page=1`)}>保管・在庫管理</p>
        <p onClick={() => history.push(`/search/industry=+works=店舗運営+keyWord=+page=1`)}>店舗運営</p>
        <p onClick={() => history.push(`/search/industry=+works=アフターサービス+keyWord=+page=1`)}>アフターサービス</p>
        <p onClick={() => history.push(`/search/industry=+works=受付案内+keyWord=+page=1`)}>受付案内</p>
        <p onClick={() => history.push(`/search/industry=+works=予約管理+keyWord=+page=1`)}>予約管理</p>
        <p onClick={() => history.push(`/search/industry=+works=客室清掃+keyWord=+page=1`)}>客室清掃</p>
        <p onClick={() => history.push(`/search/industry=+works=企画・営業+keyWord=+page=1`)}>企画・営業</p>
        <p onClick={() => history.push(`/search/industry=+works=仕入+keyWord=+page=1`)}>仕入</p>
        <p onClick={() => history.push(`/search/industry=+works=注文受付+keyWord=+page=1`)}>注文受付</p>
        <p onClick={() => history.push(`/search/industry=+works=調理+keyWord=+page=1`)}>調理</p>
        <p onClick={() => history.push(`/search/industry=+works=配膳・下膳+keyWord=+page=1`)}>配膳・下膳</p>
        <p onClick={() => history.push(`/search/industry=+works=顧客対応+keyWord=+page=1`)}>顧客対応</p>
        <p onClick={() => history.push(`/search/industry=+works=返品対応+keyWord=+page=1`)}>返品対応</p>
      </div>
      
      <h4>会社名から探す</h4>
      <div>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=株式会社Ｄｏｏｇ+page=1`)}>株式会社Ｄｏｏｇ</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=タニコー株式会社+page=1`)}>タニコー株式会社</p>
        <p onClick={() => history.push(`/search/industry=+works=+keyWord=グローリー株式会社+page=1`)}>グローリー株式会社</p>
      </div>
    </div>
    </>
  );
}

export default SaveLabor;
