import firebase from "firebase/compat/app"
import Styles from './styles.module.css';
import Progress1 from './../../image/contact/progress1.png';
import Progress2 from './../../image/contact/progress2.png';
import Progress3 from './../../image/contact/progress3.png';
import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Nav from './../9.package/nav/index';
import Footer from './../9.package/footer/index';
import DefaultImage from './../../image/DefaultImage.png';

const Contact = () => {

  const [ progress, setProgress ] = useState(1);

  const [ name, setName ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ mailAddress, setMailAddress ] = useState('');
  const [ tellPhone, setTellPhone ] = useState('');
  const [ content, setContent ] = useState('');

  const [ alert, setAlert ] = useState(0);


  var link = new URL(window.location).href;
  var id = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length)).split(',');
  // decodeURIComponent(link.slice(link.indexOf('search=') + 7, link.indexOf('+salary'))).split(',');

  const [ estimate, setEstimate ] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setEstimate(id);
    
  }, []);

  function confirmation() {
    if (company === '') {
      setAlert(1);
    } else if (name === '') {
      setAlert(2);
    } else if (tellPhone === '') {
      setAlert(3);
    } else if (mailAddress === '') {
      setAlert(4);
    } else if (content === '') {
      setAlert(5);
    } else {
      setAlert(0);
      setProgress(2);
    }
  }

  function update() {
    firebase.firestore().collection('contact').doc()
    .set({
      name: name,
      company: company,
      mailAddress: mailAddress,
      tellPhone: tellPhone,
      content: `${content} <br />  ${estimate}`,
      time: new Date(),
    }).then(() => {
      setProgress(3);
    })

    // firebase.firestore().collection('contact').doc()
    // .set({
    //   'to': mailAddress,
    //   'message': {
    //     'text': '',
    //     'subject': `${company}様 お問い合わせありがとうございます`,
    //     'html': 
    //     `※このメールはシステムからの自動返信です<br />
    //     ${name}様<br />
    //     <br />
    //     お世話になっております。<br />
    //     省力化製品に関するお問い合わせありがとうございました。<br />
    //     <br />
    //     以下の内容でお問い合わせを受け付けいたしました。<br />
    //     2営業日以内に、ご連絡いたしますので今しばらくお待ちくださいませ。<br />
    //     <br />
    //     ======== お問い合わせ内容 ========<br />
    //     お名前：${name}<br />
    //     電話番号：${tellPhone}<br />
    //     E-Mail：${mailAddress}<br />
    //     お問い合わせ内容：${content}<br />
    //     <br />`,
    //   },
    // })
    
    // firebase.firestore().collection('contact').doc()
    // .set({
    //   'to': 'info@trankllc.com',
    //   'message': {
    //     'text': '',
    //     'subject': 'ホームページ経由のお問い合わせ',
    //     'html': `${content}<br />`,
    //   },
    // }).then(() => {
    //   setProgress(3);
    // })
  }


  return (
    <>
    <Nav />
    <div className={Styles.header}>
      <h1>Contact us</h1>
      <h2>お問い合わせ</h2>
    </div>
    {
    progress === 1 ?
    <div className={Styles.contents}>
      <div className={Styles.contents_progress}>
        <div>
          <p style={{fontWeight : 'bold'}}>入力</p>
          <p>確認</p>
          <p>完了</p>
        </div>
        {
          progress === 1 ?
          <img src={Progress1} alt='' />
          : progress === 2 ?
          <img src={Progress2} alt='' />
          :
          <img src={Progress3} alt='' />
        }
      </div>
      
      {estimate.length === 0 ? <></> :
        <div className={Styles.estimate}>
          <h4>見積り製品</h4>
          <div>
            {estimate.map((data, index) =>
              <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data}?alt=media&token=`} 
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            )}
          </div>
        </div>
      }

      <div className={Styles.contents_box}>
        <h3>会社名</h3>
        <input value={company} onChange={e => setCompany(e.target.value)} placeholder="memorii株式会社" />
      </div>
      <div className={Styles.contents_box}>
        <h3>氏名</h3>
        <input value={name} onChange={e => setName(e.target.value)} placeholder="メモリ 太郎" />
      </div>
      <div className={Styles.contents_box}>
        <h3>電話番号</h3>
        <input value={tellPhone} onChange={e => setTellPhone(e.target.value)} placeholder="080-xxxx-xxxx" />
      </div>
      <div className={Styles.contents_box}>
        <h3>メールアドレス</h3>
        <input value={mailAddress} onChange={e => setMailAddress(e.target.value)} placeholder="info@memorii-works.com" />
      </div>
      <div className={Styles.contents_box}>
        <h3>お問い合わせ内容</h3>
        <TextareaAutosize className={Styles.contents_box_area} onChange={e => setContent(e.target.value)} placeholder="memorii worksに関する質問"  />
        <div></div>
      </div>
      <p className={Styles.contents_alert} style={alert === 1 ? {display : 'block'} : {display : 'none'}}>※会社名を入力してください。</p>
      <p className={Styles.contents_alert} style={alert === 2 ? {display : 'block'} : {display : 'none'}}>※氏名を入力してください。</p>
      <p className={Styles.contents_alert} style={alert === 3 ? {display : 'block'} : {display : 'none'}}>※電話番号を入力してください。</p>
      <p className={Styles.contents_alert} style={alert === 4 ? {display : 'block'} : {display : 'none'}}>※メールアドレスを入力してください。</p>
      <p className={Styles.contents_alert} style={alert === 5 ? {display : 'block'} : {display : 'none'}}>※お問い合わせ内容を入力してください。</p>
      <button className={Styles.contents_btn} onClick={() => confirmation()}  style={{margin : '0 calc((100% - 200px) / 2)'}}>確認</button>
    </div>
    : progress === 2 ?
    <div className={Styles.contents}>
      <div className={Styles.contents_progress}>
        <div>
          <p>入力</p>
          <p style={{fontWeight : 'bold'}}>確認</p>
          <p >完了</p>
        </div>
        <img src={Progress2} alt='' />
      </div>

      {estimate.length === 0 ? <></> :
        <div className={Styles.estimate}>
          <h4>見積り製品</h4>
          <div>
            {estimate.map((data, index) =>
              <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data}?alt=media&token=`} 
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            )}
          </div>
        </div>
      }

      <div className={Styles.contents_preview}>
        <h3>会社名</h3>
        <p>{company}</p>
      </div>
      <div className={Styles.contents_preview}>
        <h3>氏名</h3>
        <p>{name}</p>
      </div>
      <div className={Styles.contents_preview}>
        <h3>電話番号</h3>
        <p>{tellPhone}</p>
      </div>
      <div className={Styles.contents_preview}>
        <h3>メールアドレス</h3>
        <p>{mailAddress}</p>
      </div>
      <div className={Styles.contents_preview}>
        <h3>お問い合わせ内容</h3>
        <p>{content}</p>
      </div>
      <div className={Styles.contents_send_btn}>
        <button className={Styles.contents_btn} onClick={() => setProgress(1)}>編集</button>
        <button className={Styles.contents_btn} onClick={() => update()}>送信</button>
      </div>
    </div>
    :
    <div className={Styles.contents}>
      <div className={Styles.contents_progress}>
        <div>
          <p>入力</p>
          <p>確認</p>
          <p style={{fontWeight : 'bold'}}>完了</p>
        </div>
        {
          progress === 1 ?
          <img src={Progress1} alt='' />
          : progress === 2 ?
          <img src={Progress2} alt='' />
          :
          <img src={Progress3} alt='' />
        }
      </div>
      <p className={Styles.contents_third_btn}>
        弊社にご関心をお持ちいただき、ありがとうございます。<br />
        お問合せ内容の確認後、３営業日以内に担当者よりご連絡いたします。<br />
        引き続き何卒よろしくお願いいたします。
      </p>
    </div>
    }
    <Footer />
    </>
  );
}

export default Contact;
