import DocumentMeta from 'react-document-meta';
import Styles from './../styles.module.css';
import Nav from "./../nav/index.js";
import TopImage from "./../../../image/media/0002/top.png";
import Image1 from "./../../../image/media/0002/1.png";
import Image2 from "./../../../image/media/0002/2.png";
import Image3 from "./../../../image/media/0002/3.png";
import Footer from './../footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai"
import { useEffect } from "react";

const BlogText0001 = () => {

  const meta = {
    title: '中小企業省力化投資補助金の「カタログ」とは！？申請や方法について解説！ ',
    description: '中小企業省力化投資補助金のカタログ登録手順やメリット、対象製品に関する詳細解説。省力化製品を製造・販売する事業者向けに、カタログへの登録方法や審査要件を紹介しています。補助金対象となる製品の情報や製造事業者の登録手続きなど、全てのステップを網羅していますので、中小企業省力化投資補助金のカタログ登録での不安がありましたら、ぜひご参照ください。',
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className={Styles.media}>
        <div className={Styles.media_article}>
          
          <>
          <p className={Styles.media_date}>2024/05/03 更新日: 2024/06/07</p>
          <h1 className={Styles.media_title}>中小企業省力化投資補助金の「カタログ」とは！？申請や方法について解説！ </h1>
          <img src={TopImage} className={Styles.media_title_img} alt='事業再構築補助金' />
          </>

          <>
          <p className={Styles.media_text_text}>
            <br />
            本記事では省力化製品を製造・販売する事業者様に向けて、<strong>中小企業省力化投資補助金における「カタログ」に関する情報をまとめたものです</strong>。<br />
            カタログの登録や対象となる製品、登録されるまでの手順を中心に展開しております。<br />
          </p>
          </>

          <>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>中小企業省力化投資補助金の「カタログ」とは何か！？</span></li>
              <li><span>「カタログ」に登録される機器とは？登録するメリットは？</span></li>
              <li><span>実際の登録までの流れ</span></li>
            </ul>
          </div>
          </>

          <p className={Styles.media_text_text}>
            こんにちは。補助金コンサルタントのトランク合同会社です。<br />
            <br />
            本記事では、<strong>今注目の中小企業省力化投資補助金における「カタログ」について解説させていただきます</strong>。<br />
            「自社製品をカタログに登録したい」という方に向けて執筆させていただきましたのでぜひ最後までご覧になっていただきたいと思います。<br />
          </p>

          <>
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>「カタログ」とは？</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>カタログに登録される情報について</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>カタログ登録によるメリット</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>対象となる機器 </strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>製品カタログ登録までの手順</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className={Styles.media_text_title}>
            <h2>1.「カタログ」とは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            まず、中小企業省力化投資補助金における「カタログ」とは、<br />
            <strong>「中小企業において人手不足の解消の効果をもたらす製品があらかじめ補助の対象として登録された製品のリスト」</strong>と定義されております。<br />
            <br />
            中小企業省力化投資補助金では、このカタログに登録されている省力化製品を事業者様が購入することを補助する仕様となっており、<strong>省力化製品を製造・販売される事業者様は必見の補助金</strong>です。<br />
            <br />
            <img src={Image1} className={Styles.media_title_img} alt='補助金' />
            引用：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/product_manufacturer_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              省力化製品・省力化製品製造事業者登録要領
            </a><br />
          </p>
          </>

          <>
          <div id='2' className={Styles.media_text_title}>
            <h2>2. カタログに登録される情報について</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            <strong>中小企業省力化投資補助金のカタログに登録される情報としては、下記の9点です</strong>。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              (1) 所属カテゴリー<br />
              (2) 業種/業務領域<br />
              (3) 製品の名称<br />
              (4) 型番<br />
              (5) 製品概要<br />
              (6) 製品のURL<br />
              (7) 製造事業者名<br />
              (8) 製造事業者の所在地<br />
              (9) 製造事業者のURL<br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>(1) 所属カテゴリー</h3><br />
          <p className={Styles.media_text_text}>
            省力化製品が該当する製品カテゴリーのことです。自動清掃ロボットや自動配膳ロボットなど経済産業省が認める製品カテゴリーのどこに該当するかが判明するようになっています。<br />
            例：自動配膳ロボット<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(2) 業種/業務領域</h3><br />
          <p className={Styles.media_text_text}>
            省力化製品の効果が寄与する業種や業務領域のことです。下記の写真の通り、業種は建設業や製造業など幅広い業種に対応しており、業務領域も企画からアフターサービスまで多くの業務プロセスを網羅しています。<br />
            <img src={Image2} className={Styles.media_title_img} alt='補助金' />
          </p>
          <h3 className={Styles.media_text_text_title}>(3) 製品の名称</h3><br />
          <p className={Styles.media_text_text}>
            登録する製品の名称です。<br />
            例：自動移動配膳ロボット<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(4) 型番</h3><br />
          <p className={Styles.media_text_text}>
            登録する製品の型番です。<br />
            例；CADEBOT<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(5) 製品概要</h3><br />
          <p className={Styles.media_text_text}>
            登録される製品に関する概要の説明です。字数に制限はありませんが、100～200字程度での記載が望ましいです。<br />
            <br />
            例：独自のSLAM機能を搭載し、ロボットが自動でマッピングし、人や障害物を避けながら目的地に移動します。ロボットの管理システムを通じた遠隔モニタリング及びコントロールが可能で簡単に操作することができます。また、正面にあるディスプレイに動画や画像クラウド上から搭載可能といった機能がついています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(6) 製品のURL</h3><br />
          <p className={Styles.media_text_text}>
            登録する製品の情報が記載されているウェブページのＵＲＬです。<br />
            例：<a href='https://www.senxeed.com/robot-introduction-cadebot' target="_blank" rel="nofollow noopener noreferrer">
              登録情報一覧
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>(7) 製造事業者名</h3><br />
          <p className={Styles.media_text_text}>
            登録する製品を製造する事業者様の会社名です。<br />
            例：Senxeed Robotics株式会社<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(8) 製造事業者の所在地</h3><br />
          <p className={Styles.media_text_text}>
            製造事業者様の本社所在地です。<br />
            例：東京都港区南麻布２丁目８−２１SNUG MINAMIAZABU 302<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(9) 製造事業者のURL</h3><br />
          <p className={Styles.media_text_text}>
            製造事業者様の会社ＨＰです。<br />
            例：<a href='https://www.senxeed.com/' target="_blank" rel="nofollow noopener noreferrer">
              会社ＨＰ
            </a><br />
          </p>
          </>

          <>
          <div id='3' className={Styles.media_text_title}>
            <h2>3. カタログ登録によるメリット</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            中小企業省力化投資補助金のカタログに製品を<strong>登録することによるメリットは下記の3点が期待されます</strong>。
          </p>
          <div className={Styles.media_introduction}>
            <p>
              (1) 補助金対象となり販売数増加が期待される<br />
              (2) 一定の省力化機能が搭載していることが認定される<br />
              (3) 製品・企業ブランド力の向上<br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>(1)補助金対象となり販売数増加が期待される</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金では、「<strong>カタログに登録された＝補助金対象製品</strong>」となるため、製品を購入する事業者様は約半分の価格で購入できるという大きなメリットがあります。<br />
            <br />
            そのため、競合他社の製品よりもカタログに登録されている製品を選んだ方がお得になりますので、販売数増加が期待できます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(2)一定の省力化機能が搭載されていることが認定される</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金では、カタログ登録にあたって専門の工業会による審査が行われる仕様となっており、カタログに登録された際には省力化面から一定の機能があると認められた製品といえるでしょう。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>(3)製品・企業ブランド力の向上</h3><br />
          <p className={Styles.media_text_text}>
            中小企業省力化投資補助金のカタログに登録されることによって、「経済産業省が認めた省力化製品」「その製品を製造・販売する企業」として対外にPRすることができます。<br />
            <br />
            それにより、製品・企業のブランド力を向上させることが期待できます。<br />
          </p>
          </>

          <>
          <div id='4' className={Styles.media_text_title}>
            <h2>4.対象となる機器 </h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            中小企業省力化投資補助金では、中小企業等の人手不足解消に効果があるIoT（Internet of Things）、ロボット等の汎用的な製品が対象となります。<br />
            <br />
            事業者様それぞれに合わせたオーダーメイドの要素が強い製品というよりも、パッケージ型の汎用的な製品が最適だと考えられております。<br />
            <br />
            そのうえで、<strong>対象となる機器について下記に記載していきます</strong>。
          </p>
          <div className={Styles.media_introduction}>
            <p>
              (1) カタログ登録に最も大切な製品カテゴリー<br />
              (2) 既に発表されている製品カテゴリーについて（2024年4月現在）<br />
              (3) 対象外となる要件<br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>① カタログ登録に最も大切な製品カテゴリー</h3><br />
          <p className={Styles.media_text_text}>
            カタログ登録にあたって、最も大切となるのは経済産業省が発表する製品カテゴリーであり、その中でも<strong>製品カテゴリの省力化指標を満たしていると承認された製品が対象</strong>となっていきます。<br />
            <br />
            製品カテゴリは、工業会等が、会員企業等の製品カテゴリへの登録の要望等を踏まえ、中小企業庁に対して登録申請を行い、中小企業庁が業所管省庁等と協議して、認定を行います。<br />
            <br />
            製品カテゴリの登録申請の流れについて詳しくは「製品カテゴリ登録要領4－１申請の流れ」をご覧ください。<br />
            参照：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/product_category_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              製品カテゴリ登録要領
            </a><br />
          </p>
          <h3 className={Styles.media_text_text_title}>② 既に発表されている製品カテゴリーについて（2024年4月現在）</h3><br />
          <p className={Styles.media_text_text}>
            現在既に登録されている製品カテゴリは以下の通りです。 
            <img src={Image3} className={Styles.media_title_img} alt='補助金' />
            A清掃ロボット、B配膳ロボット、C自動倉庫、D検品・仕分システム、E無人搬送車（AGV・AMR）、Fスチームコンベクションオーブン、G券売機、H自動チェックイン機、I自動精算機<br />
            参照：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/product_catalog.pdf' target="_blank" rel="nofollow noopener noreferrer">
              製品カタログ
            </a><br />
            <br />
            当該補助金は公開されたばかりであり、これからどんどん製品カテゴリも増えていくことが予測されます。<br />
            また、ご覧になられている読者様の中でまだカテゴリーが登録されていない事業者様は該当する工業会へご連絡いただくことをお勧めいたします。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③ 対象外となる要件</h3><br />
          <p className={Styles.media_text_text}>
            <strong>気を付けておきたい対象外となる要件は以下の通りであり、ご注意ください</strong>。
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ・製品が完成されておらず、開発が必須となると想定されるもの。<br />
              ・ソフトウェアのみであり、それ専用の製品等を必要としないもの。<br />
              ・恒常的に利用されないことが想定されるもの。<br />
              ・製品単体で省力化を図るものではなく、他の製品等の使用と組み合わせない限り業務の効率化、省力化に資 さないもの。<br />
              ・製品単体で省力化を図るものではなく、付加価値向上にのみ資するもの。<br />
              ・本補助金の補助上限額を鑑みて著しく高価であるもの。また、取得財産管理台帳への記載が不要になる 50 万円未満の製品。<br />
              ・既存の製品等の機能を拡張する又は性能を向上する目的で使用されると想定されるもの。<br />
            </p>
          </div>
          <p className={Styles.media_text_text}>
            引用：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/product_manufacturer_guidelines.pdf' target="_blank" rel="nofollow noopener noreferrer">
              省力化製品・省力化製品製造事業者登録要領
            </a><br />
          </p>
          </>

          <>
          <div id='5' className={Styles.media_text_title}>
            <h2>5.製品カタログ登録までの手順</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            <strong>省力化製品がカタログに登録されるまでの手順は大きく下記の2点を経て、登録されます</strong>。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              ① 省力化製品の登録<br />
              ② 省力化製品製造事業者の登録<br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>①省力化製品の登録</h3><br />
          <p className={Styles.media_text_text}>
            まずは、<strong>製造・販売されている製品を下記手順にて登録する必要があります</strong>。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              (1)自社の省力化製品が製品カテゴリに登録されているかを確認する<br />
              (2)工業会等に「製品登録審査要請」を行い、省力化指標を満たしているか、費用対効果が高いか等の審査を受ける<br />
            </p>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            <span>(1)自社の省力化製品が製品カテゴリに登録されているかを確認する</span><br />
            製造・販売されている製品について、経済産業省が発表する製品カテゴリーに登録されているかを確認しましょう。<br />
            参考：<a href='https://shoryokuka.smrj.go.jp/assets/pdf/product_category_list.pdf' target="_blank" rel="nofollow noopener noreferrer">
              中小企業省力化投資補助金 公式HP_対象カテゴリー一覧
            </a><br />
            <br />
            <span>(2) 工業会等に「製品登録審査要請」を行い、省力化指標を満たしているか、費用対効果が高いか等の審査を受ける</span><br />
            製品カテゴリーに登録されている場合には、該当する工業会に「製品登録審査要請」をすることで製品登録申請が可能です。審査要請後、数週間で審査が完了し製品登録がされます。<br />
            <br />
            審査に必要となる書類は公式HPで発表されていますので、必要書類の作成や情報の収集をしましょう。<br />
            時間がない事業者様は当社も含むコンサルティング会社に申請サポートを依頼するのがおすすめです。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②省力化製品製造事業者の登録</h3><br />
          <p className={Styles.media_text_text}>
            省力化製品の登録が完了後には、省力化製品製造事業者としての登録が必要です。<strong>登録にあたって、下記2ステップが必要となります</strong>。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              (1)工業会等から発行された証明書を持って、事務局で「省力化製品製造事業者」としての登録<br />
              (2) 事務局へ「カタログ登録申請」を行う<br />
            </p>
          </div>
          <p className={Styles.media_text_text}>
            <span>(1)工業会等から発行された証明書を持って、事務局で「省力化製品製造事業者」としての登録</span><br />
            省力化製品登録が完了後、該当する工業会より証明書が発行されますので、それを踏まえて中小企業省力化投資補助金事務局への登録作業が必要となります。<br />
            基本的にはWeb上で完結いたします。<br />
            <br />
            ※製造事業者として既に登録が澄んでおり、過去に登録した省力化製品と同一カテゴリ内の別の省力化製品を登録する場合は不要です。<br />
            <br />
            <span>(2) 事務局へ「カタログ登録申請」を行う</span><br />
            (1)と同時に、カタログの登録形式が本補助金の要件に合致しているかの確認や製品と同時に提供するサービス等の登録を行います。このステップが完了すると、晴れてカタログに登録となります。<br />
            <br />
            ※複数の省力化製品を同時に登録することはできないため、それぞれ個別に登録を行う必要があります。<br />
          </p>
          </>

          <>
          <div id='6' className={Styles.media_text_title}>
            <h2>6.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            今回は、中小企業省力化投資補助金の省力化製品に登録したい製造事業者様へ向け、中小企業省力化投資補助金のカタログ登録までについて記事にさせていただきました。
            登録要領を各所に添付させていただきましたが、ご覧いただくと分かる通り難しい内容となっております。<br />
            <br />
            この機会に、本格的に中小企業省力化投資補助金の製品カタログに登録を検討されていましたら、弊社含め補助金に詳しい事業者へご相談ください。<br />
            右上の問い合わせより、お待ちしております。<br />
            <br />
            この度は最後まで記事をご覧いただきまして、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>
        
        {/* <Topic /> */}
        <div></div>
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
