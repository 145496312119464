import Styles from'./styles.module.css';
import Image4 from "./../../../../image/saveLabor/work/4.svg";
import Image5 from "./../../../../image/saveLabor/work/5.svg";
import Image9 from "./../../../../image/saveLabor/work/9.svg";
import Image10 from "./../../../../image/saveLabor/work/10.svg";
import Image11 from "./../../../../image/saveLabor/work/11.svg";
import Image13 from "./../../../../image/saveLabor/work/13.svg";
import Image16 from "./../../../../image/saveLabor/work/16.svg";
import Image18 from "./../../../../image/saveLabor/work/18.svg";
import Image22 from "./../../../../image/saveLabor/work/22.svg";
import { useHistory } from 'react-router-dom';

const SaveLabor = () => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.catalog_all}>
      <div className={Styles.catalog_1} onClick={() => history.push(`/search/industry=製造業+works=企画・営業+keyWord=+page=1`)}>
        <img src={Image22} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 企画・営業' />
        <h4>企画・営業</h4>
      </div>
      <div className={Styles.catalog_2} onClick={() => history.push(`/search/industry=製造業+works=見積・契約+keyWord=+page=1`)}>
        <img src={Image4} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 見積・契約' />
        <h4>見積・契約</h4>
      </div>
      <div className={Styles.catalog_3} onClick={() => history.push(`/search/industry=製造業+works=資材調達+keyWord=+page=1`)}>
        <img src={Image5} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 資材調達' />
        <h4>資材調達</h4>
      </div>
      <div className={Styles.catalog_4} onClick={() => history.push(`/search/industry=製造業+works=加工・生産+keyWord=+page=1`)}>
        <img src={Image9} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 加工・生産' />
        <h4>加工・生産</h4>
      </div>
      <div className={Styles.catalog_5} onClick={() => history.push(`/search/industry=製造業+works=検査+keyWord=+page=1`)}>
        <img src={Image10} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 検査' />
        <h4>検査</h4>
      </div>
      <div className={Styles.catalog_6} onClick={() => history.push(`/search/industry=製造業+works=保管・在庫管理+keyWord=+page=1`)}>
        <img src={Image16} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 保管・在庫管理' />
        <h4>保管・在庫管理</h4>
      </div>
      <div className={Styles.catalog_7} onClick={() => history.push(`/search/industry=製造業+works=入出庫+keyWord=+page=1`)}>
        <img src={Image13} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 入出庫' />
        <h4>入出庫</h4>
      </div>
      <div className={Styles.catalog_8} onClick={() => history.push(`/search/industry=製造業+works=販売・納品+keyWord=+page=1`)}>
        <img src={Image11} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 販売・納品' />
        <h4>販売・納品</h4>
      </div>
      <div className={Styles.catalog_9} onClick={() => history.push(`/search/industry=製造業+works=アフターサービス+keyWord=+page=1`)}>
        <img src={Image18} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | アフターサービス' />
        <h4>アフターサービス</h4>
      </div>
      <div className={Styles.catalog_10}></div>
    </div>
    </>
  );
}

export default SaveLabor;
