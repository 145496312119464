// all：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies?keyword=%E8%A3%9C%E5%8A%A9%E9%87%91&sort=created_date&order=DESC&acceptance=0
// item：https://api.jgrants-portal.go.jp/exp/v1/public/subsidies/id/a0W5h00000MyJ39EAF,

import Styles from './styles.module.css';
import DocumentMeta from 'react-document-meta';
import firebase from "firebase/compat/app";
import { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Nav from "../9.package/nav/index";
import Search from "../9.package/search/index";
import Footer from "../9.package/footer/index";
import DefaultImage from './../../image/DefaultImage.png';

import Common from "./5.works/1.common/index";
import Construction from "./5.works/2.construction/index";
import Manufacturing from "./5.works/3.manufacturing/index";
import Warehouse from "./5.works/4.warehouse/index";
import Wholesale from "./5.works/5.wholesale/index";
import Retail from "./5.works/6.retail/index";
import Stay from "./5.works/7.stay/index";
import Eating from "./5.works/8.eating/index";
import { ImCross } from "react-icons/im";

const SubisyPotal = () => {

  var link = new URL(window.location).href;

  var linkIndustry = decodeURIComponent(link.slice(link.indexOf('industry=') + 9, link.indexOf('+works')));
  var linkWorks = decodeURIComponent(link.slice(link.indexOf('+works=') + 7, link.indexOf('+keyWord')));
  var linkKeyWord = decodeURIComponent(link.slice(link.indexOf('+keyWord=') + 9, link.indexOf('+page')));
  var linkPage = decodeURIComponent(link.slice(link.indexOf('+page=') + 6, link.length));

  const history = useHistory();

  const [ list, setList ] = useState([]);
  const [ page, setPage ] = useState(1);

  const [ industry, setIndustry ] = useState('');
  const [ works, setWorks ] = useState('');
  const [ keyWord, setKeyWord ] = useState('');

  const [ switchBtn, setSwitchBtn ] = useState(0);

  const [ estimate, setEstimate ] = useState([]);

  const meta = {
    title: `${industry !== '' ? industry : ''}${industry !== '' ? '、' : ''}${works !== '' ? works : ''}${works !== '' ? '、' : ''}${keyWord !== '' ? keyWord : ''}${keyWord !== '' ? '、' : ''} の中小企業省力化投資補助金（省人化補助金・省力化補助金）設備・機器一覧`,
    description: `${industry !== '' ? industry : ''}${industry !== '' ? '、' : ''}${works !== '' ? works : ''}${works !== '' ? '、' : ''}${keyWord !== '' ? keyWord : ''}${keyWord !== '' ? '、' : ''}のの中小企業省力化投資補助金（省人化補助金・省力化補助金）設備・機器一覧をまとめています。なお、購入した設備・機器の申請に関するご案内も承っております。`,
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));

  useEffect(() => {
    
    setY(window.scrollY);
    window.addEventListener("scroll", (e) => handleNavigation(e));

    recommend();

  }, [window.scrollY]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function recommend() {
    
    setIndustry(linkIndustry);
    setWorks(linkWorks);
    setKeyWord(linkKeyWord);
    setPage(linkPage);

    if (linkIndustry === '') {
      if (linkWorks === '') {
        console.log('koko');
        firebase.firestore().collection("saveLabor").orderBy('recommend', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      } else if (linkWorks !== '') {
        firebase.firestore().collection("saveLabor").where('process', 'array-contains', linkWorks).orderBy('recommend', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      }
    } else if (linkIndustry !== '') {
      firebase.firestore().collection("saveLabor").where('industry', 'array-contains', linkIndustry).orderBy('recommend', 'desc').get()
      .then(snapShot => {
        var tmp = [];
        snapShot.forEach((doc) => {
          if (linkWorks === '' && linkKeyWord === '') {
            tmp.push({...doc.data(), id: doc.id });
          } else if (linkWorks !== '' && linkKeyWord === '') {
            if (doc.data().works.includes(linkKeyWord)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks === '' && linkKeyWord !== '') {
            if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks !== '' && linkKeyWord !== '') {
            if (doc.data().works.includes(linkWorks) && isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          }
        });
        setList(tmp);
      });
    }
  }

  function latest() {
 
    setIndustry(linkIndustry);
    setWorks(linkWorks);
    setKeyWord(linkKeyWord);
    setPage(linkPage);

    if (linkIndustry === '') {
      if (linkWorks === '') {
        console.log('koko');
        firebase.firestore().collection("saveLabor").orderBy('time', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      } else if (linkWorks !== '') {
        firebase.firestore().collection("saveLabor").where('process', 'array-contains', linkWorks).orderBy('time', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      }
    } else if (linkIndustry !== '') {
      firebase.firestore().collection("saveLabor").where('industry', 'array-contains', linkIndustry).orderBy('time', 'desc').get()
      .then(snapShot => {
        var tmp = [];
        snapShot.forEach((doc) => {
          if (linkWorks === '' && linkKeyWord === '') {
            tmp.push({...doc.data(), id: doc.id });
          } else if (linkWorks !== '' && linkKeyWord === '') {
            if (doc.data().works.includes(linkKeyWord)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks === '' && linkKeyWord !== '') {
            if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks !== '' && linkKeyWord !== '') {
            if (doc.data().works.includes(linkWorks) && isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          }
        });
        setList(tmp);
      });
    }
  }

  function views() {

    setIndustry(linkIndustry);
    setWorks(linkWorks);
    setKeyWord(linkKeyWord);
    setPage(linkPage);

    if (linkIndustry === '') {
      if (linkWorks === '') {
        console.log('koko');
        firebase.firestore().collection("saveLabor").orderBy('view', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      } else if (linkWorks !== '') {
        firebase.firestore().collection("saveLabor").where('process', 'array-contains', linkWorks).orderBy('view', 'desc').get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            if (linkKeyWord === '') {
              tmp.push({...doc.data(), id: doc.id });
            } else if (linkKeyWord !== '') {
              if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
                tmp.push({...doc.data(), id: doc.id });
              }
            }
          });
          setList(tmp);
        });
      }
    } else if (linkIndustry !== '') {
      firebase.firestore().collection("saveLabor").where('industry', 'array-contains', linkIndustry).orderBy('view', 'desc').get()
      .then(snapShot => {
        var tmp = [];
        snapShot.forEach((doc) => {
          if (linkWorks === '' && linkKeyWord === '') {
            tmp.push({...doc.data(), id: doc.id });
          } else if (linkWorks !== '' && linkKeyWord === '') {
            if (doc.data().works.includes(linkKeyWord)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks === '' && linkKeyWord !== '') {
            if (isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          } else if (linkWorks !== '' && linkKeyWord !== '') {
            if (doc.data().works.includes(linkWorks) && isAllIncludes([ linkKeyWord ], doc.data().name + doc.data().company + doc.data().category)) {
              tmp.push({...doc.data(), id: doc.id });
            }
          }
        });
        setList(tmp);
      });
    }
  }

  const [ y, setY ] = useState(0);

  const [ scrollDirection, setScrollDirection ] = useState(true);

  const handleNavigation = (e) => {
    const window = e.currentTarget;
    if (y < window.scrollY) {
      setScrollDirection(false);
    } else {
      setScrollDirection(true);
    }
    setY(window.scrollY);
  };


  return (
    <>
    <DocumentMeta {...meta}>

      <div className={scrollDirection ? Styles.animationTrue : Styles.animationFalse}>
        <Nav
          industry={linkIndustry}
          works={linkWorks}
        />
      </div>
      
      <div className={Styles.header}>
        <h1>{industry === '' && works === '' ? '' : `「${industry} ${works}」`}<br />補助金で購入出来る製品一覧</h1>
      </div>

      {industry === ''? <></> :
        <div className={Styles.category_box}>

          <h3 className={Styles.category_box_title}>{industry}の業務で使える効率化製品</h3>

          {industry == '共通' ?
            <Common />
          : industry == '建設業' ?
            <Construction />
          : industry == '製造業' ?
            <Manufacturing />
          : industry == '倉庫業' ?
            <Warehouse />
          : industry == '卸売業' ?
            <Wholesale />
          : industry == '小売業' ?
            <Retail />
          : industry == '宿泊業' ?
            <Stay />
          : industry == '飲食業' ?
            <Eating />
          : <></> }
          
        </div>
      }

      <div className={Styles.result}>

        <div className={Styles.results_box}>

          <div className={Styles.results_box_order}>
            <button style={switchBtn === 0 ? {backgroundColor : '#094284', color : 'white'} : {}} 
              onClick={() => {recommend(); setSwitchBtn(0);}}>
              おすすめ順</button>
            <button style={switchBtn === 1 ? {backgroundColor : '#094284', color : 'white'} : {}} 
              onClick={() => {latest(); setSwitchBtn(1);}}>
              新着順</button>
            <button style={switchBtn === 2 ? {backgroundColor : '#094284', color : 'white'} : {}} 
              onClick={() => {views(); setSwitchBtn(2);}}>
              閲覧数順</button>
          </div>

          <div className={Styles.list}>
            {list.length === 0 ? <></> :
            list.map((data, index) =>
              <div className={Styles.box}>
                <div className={Styles.box_header}>
                  <h6>{index + 1}</h6>
                  <Link to={`/product/id=${data.id}`}  target="_blank" rel="noopener" className={Styles.box_header_name}>
                    <h3>{data.company}</h3>
                    <h2>{data.name}</h2>
                  </Link>
                  <div className={Styles.box_header_btn}>
                    <p>見積り取得</p>
                    <input type='checkbox' className={Styles.box_header_btn_input}
                      checked={estimate.indexOf(data.id) !== -1 ? 'checked' : ''}
                      onChange={(e) => {
                        e.target.checked ? 5 <= estimate.length ? console.log() : setEstimate([...estimate, data.id]) : 
                          setEstimate(estimate.filter((v, index) => (v !== data.id)))
                      }}/>
                  </div>
                </div>
                <Link to={`/product/id=${data.id}`} target="_blank" rel="noopener">
                  <div className={Styles.box_contents}>
                    <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data.id}?alt=media&token=`} 
                      onError={e => {
                        e.target.onError = null;
                        e.target.src = DefaultImage;
                      }}/>
                    <table className={Styles.api_media_table}>
                      <tbody>
                        <tr>
                          <td className={Styles.api_media_q}><p>業種</p></td>
                          <td className={Styles.api_media_a}><p>{data.Industry}</p></td>
                        </tr>
                        <tr>
                          <td className={Styles.api_media_q}><p>業務</p></td>
                          <td className={Styles.api_media_a}><p>{data.process}</p></td>
                        </tr>
                        <tr>
                          <td className={Styles.api_media_q}><p>概要</p></td>
                          <td className={Styles.api_media_a}><p>{data.description}</p></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Link>
                <div className={Styles.estimate}>
                  <p>この製品の見積りを取得する</p>
                  <input type='checkbox' className={Styles.box_header_btn_input}
                    checked={estimate.indexOf(data.id) !== -1 ? 'checked' : ''}
                    onChange={(e) => {
                      e.target.checked ? 5 <= estimate.length ? console.log() : setEstimate([...estimate, data.id]) : 
                        setEstimate(estimate.filter((v, index) => (v !== data.id)))
                    }}/>
                </div>
              </div>
            )}
          </div>

        </div>

        <dv className={Styles.search}>
          <Search 
            industry={industry}
            setIndustry={setIndustry}
            works={works}
            setWorks={setWorks}
            keyWord={keyWord}
            setKeyWord={setKeyWord}
          />
        </dv>

      </div>

      <div className={Styles.estimate_bar}>
        <div>
          <div></div>
          <div>
            <h4>選択中のサービス<span>{estimate.length}件 / 5件</span></h4>
            <div>
              {estimate.map((data, index) =>
                <div>
                  <div onClick={() => {
                    setEstimate(estimate.filter((v, index) => (v !== data)));
                  }}>
                    <ImCross className={Styles.imCross} />
                  </div>
                  <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data}?alt=media&token=`} 
                    onError={e => {
                      e.target.onError = null;
                      e.target.src = DefaultImage;
                    }}/>
                </div>
              )}
            </div>
            <Link to={`/contact/id=${estimate}`}>
              <button>見積り取得</button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />

    </DocumentMeta>
    </>
  );
}

export default SubisyPotal;
