import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/compat/app"
import { initializeApp } from "firebase/app";
import './index.css';
import "firebase/storage";
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyDSxhqPz3nx2pvZCke-DwmawqTLxRhITXU",
  authDomain: "savelabor-f2808.firebaseapp.com",
  projectId: "savelabor-f2808",
  storageBucket: "savelabor-f2808.appspot.com",
  messagingSenderId: "97971546605",
  appId: "1:97971546605:web:11f04c1d09e707ccc25fe4",
  measurementId: "G-GE8C3WNB4F"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const analytics = firebase.analytics();
const auth = initializeApp(firebaseConfig);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();

export { storage, analytics, auth, firebase as default };
