import DocumentMeta from 'react-document-meta';
import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import Nav from '../9.package/nav/index';
import Header from './1.header/index';
import Search from './2.search/index';
import IndustryPc from './3.industry/pc/index';
import IndustryPhone from './3.industry/phone/index';
import Ranking from './4.ranking/index';
import What from './5.what/index';
import List from './7.list/index';
import Blog from './6.blog/index';
import Footer from './../9.package/footer/index';
import Styles from './styles.module.css';

import Industrysss from './stock/4.industry_a/index';
import { isMobile } from "react-device-detect";

const SaveLabor = () => {

  const meta = {
    title: `中小企業省力化投資補助金 カタログ検索サイト | 省人化補助金・省力化補助金`,
    description: `中小企業省力化投資補助金（省人化補助金・省力化補助金）のカタログに登録されている設備・機器が検索できるサイトです。なお、購入した設備・機器の申請に関するご案内も承っております。`,
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };


  const [ y, setY ] = useState(0);

  const [ scrollDirection, setScrollDirection ] = useState(true);

  const handleNavigation = (e) => {
    const window = e.currentTarget;
    if (y < window.scrollY) {
      setScrollDirection(false);
      setY(window.scrollY);
    } else {
      setScrollDirection(true);
    }
  };

  useEffect(() => {

    setY(window.scrollY);
    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, [window.scrollY]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <DocumentMeta {...meta}>
      
      <div className={Styles.background}>

        <div className={scrollDirection ? Styles.animationTrue : Styles.animationFalse}>
          <Nav />
        </div>

        <Header />
        <Search />

        {isMobile ?
          <IndustryPhone />
        :
          <IndustryPc />
        }

        <Ranking />
        <What />
        <Blog />
        <List />
        <Footer />

        {/* <Industrysss /> */}

      </div>

      {/* <Popup /> */}
      {/* <Latest /> */}
      {/* <Category /> */}
      {/* <Company /> */}

    </DocumentMeta>
    </>
  );
}

export default SaveLabor;
