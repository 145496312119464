import DocumentMeta from 'react-document-meta';
import Styles from './index.module.css';
import Nav from "./nav/index";
import Footer from './footer/index';
import Image1 from "./../../image/media/0001/top.png";
import Image2 from "./../../image/media/0002/top.png";
import Image3 from "./../../image/media/0003/top.png";
import Image4 from "./../../image/media/0004/top.png";
import Image5 from "./../../image/media/0005/top.png";
import Image6 from "./../../image/media/0006/top.png";
import Image7 from "./../../image/media/0007/top.png";
import Image8 from "./../../image/media/0008/top.png";
import { useEffect } from "react";

import { Link } from 'react-router-dom';

const BlogText0001 = () => {

  const meta = {
    title: '省力化製品に関するメディア | 中小企業省力化投資補助金（省人化・省力化補助金）',
    description: '本記事は省力化製品に関する詳しい解説や実際に導入するために必要な補助金の活用方法等をまとめているサイトです。飲食業、宿泊業、製造業など多種多様な業種に向けた情報を配信しているメディアですので、ぜひ参考にしていただければと思います。',
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <DocumentMeta {...meta}>
      <div className={Styles.business_media_color}>
        <Nav />

        <div className={Styles.business_media_speace}></div>

        <div className={Styles.business_media_title}>
          <div></div>
          <h3>省力化製品の概要「レベル１」</h3>
        </div>

        <div className={Styles.business_media_box}>
          <Link to={`/media/0001`}>
            <img src={Image1} alt='' />
            <h3>【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！</h3>
            <p>更新日：2024/05/3</p>
          </Link>
          <Link to={`/media/0002`}>
            <img src={Image2} alt='' />
            <h3>中小企業省力化投資補助金の「カタログ」とは！？申請や方法について解説！</h3>
            <p>更新日：2024/05/3</p>
          </Link>
          <Link to={`/media/0003`}>
            <img src={Image3} alt='' />
            <h3>自動精算機の導入価格をメーカー別に解説！（導入コスト & 製品比較）</h3>
            <p>更新日：2024/05/3</p>
          </Link>
          <Link to={`/media/0004`}>
            <img src={Image4} alt='' />
            <h3>アルメックスの自動精算機６台の違いを比較！（業種別・機能別・特徴など）</h3>
            <p>更新日：2024/05/3</p>
          </Link>
          <Link to={`/media/0005`}>
            <img src={Image5} alt='' />
            <h3>【2024年最新】自動精算機を購入する際に活用できる補助金5選！</h3>
            <p>更新日：2024/05/12</p>
          </Link>
          <Link to={`/media/0006`}>
            <img src={Image6} alt='' />
            <h3>今注目の猫型配膳ロボット！導入価格・メリットを詳しく解説！</h3>
            <p>更新日：2024/05/12</p>
          </Link>
          <Link to={`/media/0007`}>
            <img src={Image7} alt='' />
            <h3>【2024年最新】配膳ロボットに活用できる補助金４選！特におすすめは省力化補助金！</h3>
            <p>更新日：2024/05/12</p>
          </Link>
          <Link to={`/media/0008`}>
            <img src={Image8} alt='' />
            <h3>【2024年最新】券売機に使える補助金を紹介！省力化補助金がおすすめ！</h3>
            <p>更新日：2024/05/12</p>
          </Link>
          <Link to={`/media/0001`}>
            <p></p>
          </Link>
        </div>

      </div>
      
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;

