import DocumentMeta from 'react-document-meta';
import Styles from './styles.module.css';
import Footer from '../9.package/footer/index';
import List from '../1.home/7.list/index';
import { useEffect, useState, useLayoutEffect } from "react";
import firebase from "firebase/compat/app";
import DefaultImage from './../../image/DefaultImage.png';

import Common from "./../2.results/5.works/1.common";
import Construction from "./../2.results/5.works/2.construction/index";
import Manufacturing from "./../2.results/5.works/3.manufacturing/index";
import Warehouse from "./../2.results/5.works/4.warehouse/index";
import Wholesale from "./../2.results/5.works/5.wholesale/index";
import Retail from "./../2.results/5.works/6.retail/index";
import Stay from "./../2.results/5.works/7.stay/index";
import Eating from "./../2.results/5.works/8.eating/index";

import Nav from "./../9.package/nav/index";
import { ImCross } from "react-icons/im";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SaveLabor = () => {

  const [ image, setImage ] = useState('');
  const [ category, setCategory ] = useState('');
  const [ industry, setIndustry ] = useState([]);
  const [ process, setProcess ] = useState('');
  const [ name, setName ] = useState('');
  const [ model, setModel ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ url, setUrl ] = useState('');
  const [ company, setCompany ] = useState("");
  const [ address, setAddress ] = useState('');
  const [ companyUrl, setCompanyUrl ] = useState("");

  const [ recommendList, setRecommendList ] = useState([]);

  const [ estimate, setEstimate ] = useState([]);

  var link = new URL(window.location).href;

  var id = link.slice(link.indexOf('id=') + 3, link.length);

  useEffect(() => {

    setY(window.scrollY);
    window.addEventListener("scroll", (e) => handleNavigation(e));

    firebase.firestore().collection("saveLabor").doc(id).get()
    .then((doc) => {

      if (doc.exists) {
        setCategory(doc.data().category);
        setIndustry(doc.data().industry);
        setProcess(doc.data().process);
        setName(doc.data().name);
        setModel(doc.data().model);
        setDescription(doc.data().description);
        setUrl(doc.data().url);
        setCompany(doc.data().company);
        setAddress(doc.data().address);
        setCompanyUrl(doc.data().companyUrl);
        setImage(doc.data().image);
  
        firebase.firestore()
        .collection("saveLabor").where('process', 'array-contains-any', doc.data().process)
        .orderBy('recommend', 'desc').limit(5).get()
        .then(snapShot => {
          var tmp = [];
          snapShot.forEach((doc) => {
            tmp.push({...doc.data(), id: doc.id });
          });
          setRecommendList(tmp);
        });
      }
    });

    firebase.firestore().collection("saveLabor").doc(id)
    .update({
      ranking: firebase.firestore.FieldValue.increment(1),
    })
    
  }, [window.scrollY]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ y, setY ] = useState(0);

  const [ scrollDirection, setScrollDirection ] = useState(true);

  const handleNavigation = (e) => {
    const window = e.currentTarget;
    if (y < window.scrollY) {
      setScrollDirection(false);
    } else {
      setScrollDirection(true);
    }
    setY(window.scrollY);
  };


  const meta = {
    title: `${name}の中小企業省力化投資補助金（省人化補助金・省力化補助金）の活用`,
    description: `${name}の中小企業省力化投資補助金（省人化補助金・省力化補助金）の活用に関する詳細をまとめています。${description}`,
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>

      <div className={scrollDirection ? Styles.animationTrue : Styles.animationFalse}>
        <Nav 
          industry={''}
          works={''}
        />
      </div>
      
      <div className={Styles.header}>
        <h1>「飲食業 配膳ロボット」<br />補助金で購入出来る製品一覧</h1>
      </div>

      <div className={Styles.background}>

        <div className={Styles.contents}>

          <div className={Styles.contents_product}>

            <div className={Styles.contents_product_header}>
              <h4>1</h4>
              <div>
                <h4>Senxeed Robotics株式会社</h4>
                <h5>{name}</h5>
              </div>
            </div>

            <div className={Styles.contents_product_details}>

              <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${image}?alt=media&token=`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
                
              <table className={Styles.contents_product_table}>
                <tbody>
                  <tr>
                    <th><p>カテゴリー</p></th>
                    <td className={Styles.contents_product_a}><p>{category}</p></td>
                  </tr>
                  <tr>
                    <th><p>業種</p></th>
                    <td className={Styles.contents_product_a}><p>{industry}</p></td>
                  </tr>
                  <tr>
                    <th><p>業務</p></th>
                    <td className={Styles.contents_product_a}><p>{process}</p></td>
                  </tr>
                  <tr>
                    <th><p>機械名</p></th>
                    <td className={Styles.contents_product_a}><p>{name}</p></td>
                  </tr>
                  <tr>
                    <th><p>型番</p></th>
                    <td className={Styles.contents_product_a}><p>{model}</p></td>
                  </tr>
                  <tr>
                    <th><p>url</p></th>
                    <td className={Styles.contents_product_a}>
                      <a href={url}>
                        <p>製品の詳細を見る</p>
                      </a>  
                    </td>
                  </tr>
                  <tr>
                    <th><p>会社名</p></th>
                    <td className={Styles.contents_product_a}><p>{company}</p></td>
                  </tr>
                  <tr>
                    <th><p>会社住所</p></th>
                    <td className={Styles.contents_product_a}><p>{address}</p></td>
                  </tr>
                  <tr>
                    <th><p>会社URL</p></th>
                    <td className={Styles.contents_product_a}>
                      <a href={companyUrl}>
                        <p>会社のホームページを見る</p>
                      </a>  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h3>本設備の概要</h3>

            <p>{description}</p>

            <button
              onClick={() => {
                estimate.includes(id) ? 5 <= estimate.length ? console.log() : 
                  setEstimate(estimate.filter((v, index) => (v !== id))) : 
                  setEstimate([...estimate, id])
              }}>
              本設備の見積りを取得
            </button>

          </div>

          <div className={Styles.contents_banner}></div>

        </div>

        <div className={Styles.recommend}>

          <h4>あなたにおすすめの製品</h4>

          <div className={Styles.recommend_contents}>

            <div className={Styles.recommend_contents_header}>
              <h4>名称</h4>
              <h4>写真</h4>
              <h4>業種</h4>
              <h4>業務</h4>
              <h4>見積取得</h4>
            </div>

            <div className={Styles.recommend_contents_lists}>

              {recommendList.length === 0 ? <></> :
              recommendList.map((data, index) =>
                <div className={Styles.recommend_contents_lists_box}>
                  <h4>配膳ロボット「CADEBOT」</h4>
                  <div className={Styles.recommend_contents_lists_box_image}>
                    <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data.id}?alt=media&token=`} 
                      onError={e => {
                        e.target.onError = null;
                        e.target.src = DefaultImage;
                      }}/>
                  </div>
                  <h4>{data.industry}</h4>
                  <h4>{data.process}</h4>
                  <div className={Styles.recommend_contents_lists_checkbox}>
                    <h4>見積取得</h4>
                    <input type='checkbox' className={Styles.box_header_btn_input}
                      checked={estimate.indexOf(data.id) !== -1 ? 'checked' : ''}
                      onChange={(e) => {
                        e.target.checked ? 5 <= estimate.length ? console.log() : setEstimate([...estimate, data.id]) : 
                          setEstimate(estimate.filter((v, index) => (v !== data.id)))
                      }}/>
                  </div>
                </div>
              )}

            </div>

          </div>
        </div>

        <div className={Styles.category_box}>
          {/* <Works industry={'共通'} /> */}
          <h3 className={Styles.category_box_title}>飲食店の方が使える効率化製品</h3>

          {industry == '共通' ?
            <Common />
          : industry == '建設業' ?
            <Construction />
          : industry == '製造業' ?
            <Manufacturing />
          : industry == '倉庫業' ?
            <Warehouse />
          : industry == '卸売業' ?
            <Wholesale />
          : industry == '小売業' ?
            <Retail />
          : industry == '宿泊業' ?
            <Stay />
          : industry == '飲食業' ?
            <Eating />
          : <></> }
        
        </div>

      </div>

      <div className={Styles.estimate_bar}>
        <div>
          <div></div>
          <div>
            <h4>選択中のサービス<span>{estimate.length}件 / 5件</span></h4>
            <div>
              {estimate.map((data, index) =>
                <div>
                  <div onClick={() => {
                    setEstimate(estimate.filter((v, index) => (v !== data)));
                  }}>
                  <ImCross className={Styles.imCross} />
                  </div>
                  <img src={`https://firebasestorage.googleapis.com/v0/b/savelabor-f2808.appspot.com/o/saveLabor%2F${data}?alt=media&token=`} 
                    onError={e => {
                      e.target.onError = null;
                      e.target.src = DefaultImage;
                    }}/>
                </div>
              )}
            </div>
            <Link to={`/contact/id=${estimate}`}>
              <button>見積り取得</button>
            </Link>
          </div>
        </div>
      </div>

      <List />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default SaveLabor;
