import Styles from'./styles.module.css';
import Image4 from "./../../../../image/saveLabor/work/4.svg";
import Image5 from "./../../../../image/saveLabor/work/5.svg";
import Image6 from "./../../../../image/saveLabor/work/6.svg";
import Image7 from "./../../../../image/saveLabor/work/7.svg";
import Image8 from "./../../../../image/saveLabor/work/8.svg";
import Image10 from "./../../../../image/saveLabor/work/10.svg";
import Image18 from "./../../../../image/saveLabor/work/18.svg";
import Image22 from "./../../../../image/saveLabor/work/22.svg";
import { useHistory } from 'react-router-dom';

const SaveLabor = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.catalog_all}>
      <div className={Styles.catalog_1} onClick={() => history.push(`/search/industry=建設業+works=企画・営業+keyWord=+page=1`)}>
        <img src={Image22} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 企画・営業' />
        <h4>企画・営業</h4>
      </div>
      <div className={Styles.catalog_2} onClick={() => history.push(`/search/industry=建設業+works=見積・契約+keyWord=+page=1`)}>
        <img src={Image4} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 見積・契約' />
        <h4>見積・契約</h4>
      </div>
      <div className={Styles.catalog_3} onClick={() => history.push(`/search/industry=建設業+works=資材調達+keyWord=+page=1`)}>
        <img src={Image5} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 資材調達' />
        <h4>資材調達</h4>
      </div>
      <div className={Styles.catalog_4} onClick={() => history.push(`/search/industry=建設業+works=調査・測量+keyWord=+page=1`)}>
        <img src={Image6} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 調査・測量' />
        <h4>調査・測量</h4>
      </div>
      <div className={Styles.catalog_5} onClick={() => history.push(`/search/industry=建設業+works=施工+keyWord=+page=1`)}>
        <img src={Image7} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 施工' />
        <h4>施工</h4>
      </div>
      <div className={Styles.catalog_6} onClick={() => history.push(`/search/industry=建設業+works=検査+keyWord=+page=1`)}>
        <img src={Image10} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 検査' />
        <h4>検査</h4>
      </div>
      <div className={Styles.catalog_7} onClick={() => history.push(`/search/industry=建設業+works=引渡+keyWord=+page=1`)}>
        <img src={Image8} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 引渡' />
        <h4>引き渡し</h4>
      </div>
      <div className={Styles.catalog_8} onClick={() => history.push(`/search/industry=建設業+works=アフターサービス+keyWord=+page=1`)}>
        <img src={Image18} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | アフターサービス' />
        <h4>アフターサービス</h4>
      </div>
      <div className={Styles.catalog_9}></div>
      <div className={Styles.catalog_10}></div>
    </div>
    </>
  );
}

export default SaveLabor;
