// AIzaSyDSxhqPz3nx2pvZCke-DwmawqTLxRhITXU
// https://maps.googleapis.com/maps/api/place/nearbysearch/json?key=AIzaSyDSxhqPz3nx2pvZCke-DwmawqTLxRhITXU&location=35.6987769,139.76471&radius=50000&keyword=%E7%BE%8E%E5%AE%B9%E5%AE%A4

import DocumentMeta from 'react-document-meta';
import Styles from './../styles.module.css';
import Nav from "../nav/index.js";
import TopImage from "./../../../image/media/0015/top.png";
import Image1 from "./../../../image/media/0015/1.png";
import Image2 from "./../../../image/media/0015/2.png";
import Image3 from "./../../../image/media/0015/3.png";
import Image4 from "./../../../image/media/0015/4.png";
import Image5 from "./../../../image/media/0015/5.png";
import Image6 from "./../../../image/media/0015/6.png";
import Image7 from "./../../../image/media/0015/7.png";
import Image8 from "./../../../image/media/0015/8.png";
import Image9 from "./../../../image/media/0015/9.png";
import Image10 from "./../../../image/media/0015/10.png";
import Image11 from "./../../../image/media/0015/11.png";
import Image12 from "./../../../image/media/0015/12.png";
import Image13 from "./../../../image/media/0015/13.png";
import Image14 from "./../../../image/media/0015/14.png";
import Image15 from "./../../../image/media/0015/15.png";
import Image16 from "./../../../image/media/0015/16.png";
import Image17 from "./../../../image/media/0015/17.png";
import Image18 from "./../../../image/media/0015/18.png";
import Footer from '../footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai";
import { useEffect } from "react";

const BlogText0001 = () => {

  const meta = {
    title: 'AGV（無人搬送車）の商品18選をメーカー別一覧で紹介！最適価格での1台を見つけるためのガイド',
    description: '現在AGV（無人搬送車）の技術は大幅に進歩しており、多くの製造業者や物流業者での導入が進んでいます。この記事では多くの種類・メーカーが存在するAGVの基礎知識、製品リスト、価格を通じて、自社に最適な1台を見つけるためのガイドを提供していきます。',
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
 
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className={Styles.media}>
        <div className={Styles.media_article}>

          <>
          <p className={Styles.media_date}>2024/05/20 更新日: 2024/05/20</p>
          <h1 className={Styles.media_title}>AGV（無人搬送車）の商品18選をメーカー別一覧で紹介！最適価格での1台を見つけるためのガイド</h1>
          <img src={TopImage} className={Styles.media_title_img} alt='事業再構築補助金' />
          </>

          <>
          <p className={Styles.media_text_text}>
            <br />
            現在AGV（無人搬送車）の技術は大幅に進歩しており、多くの製造業者や物流業者での導入が進んでいます。<br />
            <br />
            この記事では多くの種類・メーカーが存在するAGVの基礎知識、製品リスト、価格を通じて、自社に最適な1台を見つけるためのガイドを提供していきます。<br />
          </p>
          </>

          <>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>AGVの種類・用途の違い</span></li>
              <li><span>AGVのメーカー・製品一覧</span></li>
              <li><span>各AGV製品の特徴</span></li>
            </ul>
          </div>
          </>

          <>
          <p className={Styles.media_text_text}>
            近年、製造業や物流業を中心に人手不足や生産性向上のために、AGV（無人搬送車）の導入が活発化しています。<br />
            しかし、AGVの中でも様々なメーカーから様々な種類の製品が販売されています。<br />
            <br />
            そこで、この記事では、業界別にAGVを選ぶ際のポイントとメーカー比較を通して、最適なAGVの選び方を紹介していこうと思います。<br />
          </p>
          </>

          <>
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>はじめに</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>AGVとは</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>まとAGVの種類め</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>AGV導入のメリット</strong></AnchorLink></li>
                <li><AnchorLink href="#5" offset="100"><strong>AGVの製品一覧</strong></AnchorLink></li>
                <li><AnchorLink href="#6" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='2' className={Styles.media_text_title}>
            <h2>2.AGVとは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            AGV（Automated Guided Vehicle）は、磁気テープやレーザー、二次元バーコードなどの誘導体に沿って走行する無人搬送車です。<br />
            人手を介さずに荷物を運搬することができ、製造業や物流業を中心に、様々な業種で導入されています。<br />
          </p>
          </>

          <>
          <div id='3' className={Styles.media_text_title}>
            <h2>3.AGVの種類</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            AGVは、走行方式や搭載機能、用途などによって、様々な種類に分類されます。<br />
            <br />
            <span>3.1 走行方式による分類</span><br />
            <strong>・経路誘導式AGV</strong><br />
            磁気テープや光反射テープ、電磁誘導ケーブルなどの誘導体に沿って走行するAGVです。最も一般的な種類のAGVです。<br />
            <strong>・自律移動式AGV</strong><br />
            カメラやレーザーセンサーなどを搭載し、周囲の環境を認識しながら自律的に走行するAGVです。誘導体に頼らずに走行できるため、柔軟性の高い運用が可能です。<br />
            <strong>・追従式AGV</strong><br />
            人や先行する車両などに一定の距離を保って追従するAGVです。ラインのない場所でも走行できるため、広い空間での運用に適しています。<br />
            <br />
            <span>3.2 用途による分類</span><br />
            AGVは、走行方式や搭載機能、用途などによっても様々な種類に分類されます。<br />
            代表的な以下の6種類のAGVについて、それぞれの特徴とメリットを説明します。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              <strong>・積載型</strong><br />
              <strong>・低床型</strong><br />
              <strong>・牽引型</strong><br />
              <strong>・重量型</strong><br />
              <strong>・屋外型</strong><br />
              <strong>・コンベア型</strong><br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>1.積載型</h3><br />
          <p className={Styles.media_text_text}>
            台車に荷物を載せて運搬するタイプのAGVです。<br />
            最も一般的な種類のAGVで、様々な種類の荷物を運搬することができます。<br />
            <br />
            <span>メリット</span><br />
            ・汎用性が高い<br />
            ・導入コストが比較的安価<br />
            ・設置や運用が容易<br />
            <br />
            <span>強み</span><br />
            ・個別の荷物の運搬に適している<br />
            ・狭い通路や複雑なレイアウトにも対応できる<br />
          </p>
          <h3 className={Styles.media_text_text_title}>2.低床型</h3><br />
          <p className={Styles.media_text_text}>
            床面から低い位置に荷物を載せて運搬するタイプのAGVです。<br />
            パレットやコンテナなどの大型荷物を運搬するのに適しています。<br />
            <br />
            <span>メリット</span><br />
            ・大型荷物の運搬に適している<br />
            ・積み下ろし作業が容易<br />
            ・車輪がない場合でも棚ごと移動可能<br />
            <br />
            <span>強み</span><br />
            ・フォークリフトなどの補助なしで荷物の運搬が可能<br />
            ・倉庫や工場などの広い空間での運用に適している<br />
          </p>
          <h3 className={Styles.media_text_text_title}>3.牽引型</h3><br />
          <p className={Styles.media_text_text}>
            台車などを牽引して運搬するタイプのAGVです。<br />
            大量の荷物をまとめて運搬するのに適しています。荷台の連結も可能なため、複数の台車を一度に運搬することも可能です。<br />
            <br />
            <spam>メリット</spam><br />
            ・大量の荷物をまとめて運搬できる<br />
            ・複数の製品を同時に運搬できる<br />
            <br />
            <span>強み</span><br />
            ・長距離の運搬に適している<br />
            ・高重量の運用に適している<br />
          </p>
          <h3 className={Styles.media_text_text_title}>4.重量型</h3><br />
          <p className={Styles.media_text_text}>
            重量物の運搬に特化したAGVです。<br />
            平らな積み台を搭載しており、重い荷物を安全に運搬することができます。<br />
            <br />
            <span>メリット</span><br />
            ・重量物の運搬に適している<br />
            ・作業者の負担を軽減できる<br />
            ・安全性を向上できる<br />
            <br />
            <span>強み</span><br />
            ・機械部品や自動車部品などの重量物を運搬できる<br />
            ・危険な作業をAGVに任せることができる<br />
          </p>
          <h3 className={Styles.media_text_text_title}>5.重量型</h3><br />
          <p className={Styles.media_text_text}>
            屋外での使用に耐えられるように防塵・防水対策が施されたAGVです。<br />
            工場や倉庫などの屋外環境で運搬作業を行うのに適しています。<br />
            <br />
            <span>メリット</span><br />
            ・屋外での使用が可能<br />
            ・悪天候にも対応できる<br />
            ・汎用性が高い<br />
            <br />
            <span>強み</span><br />
            ・建設現場や物流センターなどの屋外施設での運用に適している<br />
            ・温度変化や振動などの過酷な環境にも対応できる<br />
          </p>
          <h3 className={Styles.media_text_text_title}>6.コンベア型</h3><br />
          <p className={Styles.media_text_text}>
            ベルトコンベアを搭載したAGVです。<br />
            荷物をベルトコンベアに乗せて運搬するもので、積み下ろし作業まで自動で行うことができます。<br />
            <br />
            <span>メリット</span><br />
            ・大量の荷物を連続的に運搬できる<br />
            ・作業効率を大幅に向上できる<br />
            ・省人化を実現できる<br />
            <br />
            <span>強み</span><br />
            ・生産ラインや倉庫での搬送作業に適している<br />
            ・24時間365日稼働できる<br />
          </p>
          <h3 className={Styles.media_text_text_title}>本項目のまとめ</h3><br />
          <p className={Styles.media_text_text}>
            AGVは大きく分けて6つの種類に分かれています。それは①積載型、②低床型、③牽引型、④重量型、⑤屋外型、⑥コンベア型の6種類であり、それぞれに得意とする分野（外部環境、重量、機能など）が存在しています。<br />
            <br />
            AGVの導入を検討されている方は自社の環境に合わせてどの種類のAGVを導入すべきかを6種類の中から検討いただけると良い選択が可能になると思います<br />
          </p>
          </>

          <>
          <div id='4' className={Styles.media_text_title}>
            <h2>4.AGV導入のメリット</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            AGVを導入すると、以下のようなメリットが得られます。<br />
            <br />
            <strong>人件費の削減</strong><br />
            人手を介さずに荷物を運搬することで、人件費を削減することができます。<br />
            <strong>労働環境の改善</strong><br />
            重労働や危険な作業をAGVに任せることで、労働環境を改善することができます。<br />
            <strong>生産性の向上</strong><br />
            作業の自動化により、生産性を向上させることができます。<br />
            <strong>安全性の向上</strong><br />
            人為的なミスによる事故を減らすことができます。<br />
            <strong>柔軟性の向上</strong><br />
            搬送ルートや作業内容を簡単に変更できるため、柔軟性の高い運用が可能になります。<br />
          </p>
          </>

          <>
          <div id='5' className={Styles.media_text_title}>
            <h2>5.AGVの製品一覧</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            各項目ごとのオススメを記載していこうと思います。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>1.積載型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>無人搬送機WYN-200<br />（田辺工業株式会社）</strong></td>
                <td>サイズ：<br />W638mm L838mm H500mm（荷台高さ 370mm）<br />搬送重量：200kg<br />速度：30m/分</td>
                <td>
                  <img src={Image1} className={Styles.media_title_img} alt='無人搬送機WYN-200' />
                  <a href='https://www.ipros.jp/product/detail/2000765642' target="_blank" rel="nofollow noopener noreferrer">
                    無人搬送機WYN-200
                  </a>
                </td>
                <td>一般的な磁気テープの代わりに黒色のビニールテープを用いた誘導方式で走る</td>
              </tr>
              <tr>
                <td>天板移載型AGV<br />TRAY（トレイ）</td>
                <td>サイズ：550mm×550mm×550mm<br />搬送重量：150kg<br />速度：60m/分</td>
                <td>
                  <img src={Image2} className={Styles.media_title_img} alt='天板移載型AGV' />
                  <a href='https://4on.jp/agv/lineup/tray/' target="_blank" rel="nofollow noopener noreferrer">
                    天板移載型AGV
                  </a>
                </td>
                <td>天板移載タイプのAGV<br />他の機器との連携機能も有しており、移載カスタムも可能</td>
              </tr>
              <tr>
                <td>リフター搭載型AGV<br />（LONYU）</td>
                <td>サイズ：L1300 * W1250 * H801mm<br />搬送重量：1トン<br />速度：60m/分</td>
                <td>
                  <img src={Image3} className={Styles.media_title_img} alt='リフター搭載型AGV' />
                  <a href='https://lonyu.jp/portfolio/%e3%83%aa%e3%83%95%e3%82%bf%e3%83%bc%e6%90%ad%e8%bc%89%e5%9e%8bagv/' target="_blank" rel="nofollow noopener noreferrer">
                    リフター搭載型AGV
                  </a>
                </td>
                <td>ソーラーパネルフィルム等、業種を問わずロール状のものの運搬に特化したAGV</td>
              </tr>
            </tbody>
          </table>

          <h3 className={Styles.media_text_text_title}>2.低床型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>極低床型AGV HRVTシリーズ（株式会社ヘッズ）</strong></td>
                <td>サイズ：(L) 1,500× (W) 700× (H) 190mm<br />速度：39m/min<br />搬送重量：400kg</td>
                <td>
                  <img src={Image4} className={Styles.media_title_img} alt='極低床型AGV' />
                  <a href='https://metoree.com/products/14239/' target="_blank" rel="nofollow noopener noreferrer">
                    極低床型AGV
                  </a>
                </td>
                <td>狭い道幅にも対応可能<br />低床タイプより低いため主にカゴ台車対応</td>
              </tr>
              <tr>
                <td>低床潜り込み型AGV CREW<br />（四恩システム株式会社）</td>
                <td>サイズ：450mm×1,000mm×180mm<br />搬送重量：300kg<br />速度：3.60km/h</td>
                <td>
                  <img src={Image5} className={Styles.media_title_img} alt='低床潜り込み型AGV' />
                  <a href='https://www.ipros.jp/product/detail/2000760782/' target="_blank" rel="nofollow noopener noreferrer">
                    低床潜り込み型AGV
                  </a>
                </td>
                <td>磁気スポット・床認識・VSLAM(カメラによる空間認識)による搬送が可能</td>
              </tr>
              <tr>
                <td>低床型AGV H500-JFL<br />（株式会社KKS）</td>
                <td>サイズ：(L)1470×(W)590×(H)180(mm)<br />速度：30m/分<br />搬送重量：500kg</td>
                <td>
                  <img src={Image6} className={Styles.media_title_img} alt='低床型AGV' />
                  <a href='https://kks-j.co.jp/h500/' target="_blank" rel="nofollow noopener noreferrer">
                    低床型AGV
                  </a>
                </td>
                <td>主にカゴ台車対応<br />500kgまで搬送可能</td>
              </tr>
            </tbody>
          </table>

          <h3 className={Styles.media_text_text_title}>3.牽引型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>台車牽引型AGV HRDシリーズ<br />（株式会社ヘッズ）</strong></td>
                <td>サイズ：(L) 1,000× (W) 700× (H) 1,100mm<br />速度：60m/min<br />搬送重量：2t</td>
                <td>
                  <img src={Image7} className={Styles.media_title_img} alt='株式会社ヘッズ' />
                  <a href='https://metoree.com/products/14242/' target="_blank" rel="nofollow noopener noreferrer">
                    株式会社ヘッズ
                  </a>
                </td>
                <td>2tまで牽引可能<br />台車の自動取り付け、自動切り離しも可能</td>
              </tr>
              <tr>
                <td>無人搬送車 牽引型AGV OUT<br />（四恩システム株式会社）</td>
                <td>サイズ：827mm×1,323mm×874mm<br />搬送重量：1,000kg<br />速度： 60m/分</td>
                <td>
                  <img src={Image8} className={Styles.media_title_img} alt='無人搬送車' />
                  <a href='https://metoree.com/products/25578/' target="_blank" rel="nofollow noopener noreferrer">
                    無人搬送車
                  </a>
                </td>
                <td>防水機能が備わっており悪路走破性もあるため、屋外での走行も可能</td>
              </tr>
              <tr>
                <td>牽引型 AGV<br />（株式会社シコウ）</td>
                <td>サイズ：W700×L1100×H750<br />搬送重量：1,000kg<br />速度：59m/分</td>
                <td>
                  <img src={Image9} className={Styles.media_title_img} alt='牽引型 AGV' />
                  <a href='https://shiko.biz/works/%e9%9b%bb%e7%a3%81%e8%aa%98%e5%b0%8e%e6%96%b9%e5%bc%8fagv%e3%81%8b%e3%82%89%e7%a3%81%e6%b0%97%e8%aa%98%e5%b0%8e%e6%96%b9%e5%bc%8fagv%e3%81%b8%e3%81%ae%e6%9b%b4%e6%96%b0%e4%ba%8b%e4%be%8b/' target="_blank" rel="nofollow noopener noreferrer">
                    牽引型 AGV
                  </a>
                </td>
                <td>荷下ろしを自動で行ってくれると同時に、完了信号も通達してくれる</td>
              </tr>
            </tbody>
          </table>

          <h3 className={Styles.media_text_text_title}>4.重量型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>AGV（無人バッテリー台車）<br />（株式会社佐野車輌製作所）</strong></td>
                <td>サイズ：<br />L5500*W2500*H1000<br />速度：30m/分<br />搬送重量：50t</td>
                <td>
                  <img src={Image10} className={Styles.media_title_img} alt='AGV（無人バッテリー台車）' />
                  <a href='https://teamsano.co.jp/products/electric/sd.html' target="_blank" rel="nofollow noopener noreferrer">
                    AGV（無人バッテリー台車）
                  </a>
                </td>
                <td>セレクトスイッチの切り替えによって、リモコン操作でコース外への運用も可能</td>
              </tr>
              <tr>
                <td><strong>240t積載リフター搭載型AGV<br />（LONYU）</strong></td>
                <td>サイズ：<br />L9000*W3400*H800mm<br />速度：30m/分<br />搬送重量：200T</td>
                <td>
                  <img src={Image11} className={Styles.media_title_img} alt='240t積載リフター搭載型AGV' />
                  <a href='https://lonyu.jp/portfolio/240t%e7%a9%8d%e8%bc%89%e3%83%aa%e3%83%95%e3%82%bf%e3%83%bc%e6%90%ad%e8%bc%89%e5%9e%8bagv/' target="_blank" rel="nofollow noopener noreferrer">
                    240t積載リフター搭載型AGV
                  </a>
                </td>
                <td>マイナス30℃の環境下でも運行可能<br />240まで搬送可能</td>
              </tr>
              <tr>
                <td><strong>重量級型AGV<br />（株式会社シコウ）</strong></td>
                <td>サイズ：<br />W2650×L5800×H820<br />速度：25m/分<br />搬送重量：40t</td>
                <td>
                  <img src={Image12} className={Styles.media_title_img} alt='重量級型AGV' />
                  <a href='https://shiko.biz/works/%e8%b6%85%e5%a4%a7%e5%9e%8b%e9%87%8d%e9%87%8f%e7%89%a9%e6%90%ac%e9%80%81%e3%82%b7%e3%82%b9%e3%83%86%e3%83%a0%ef%bc%88%e9%96%a2%e6%9d%b1%e5%9c%b0%e5%8c%ba%e6%9f%90%e8%a3%bd%e9%80%a0%e6%a5%ad%ef%bc%89/' target="_blank" rel="nofollow noopener noreferrer">
                    重量級型AGV
                  </a>
                </td>
                <td>カスタマイズによって±1mmの精度で移動、停止をすることが可能</td>
              </tr>
            </tbody>
          </table>

          <h3 className={Styles.media_text_text_title}>5.屋外型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>屋外型AGV HROシリーズ<br />（株式会社ヘッズ）</strong></td>
                <td>サイズ：<br />(L)1115×(W)700×(H)1100mm<br />速度：60m/min<br />搬送重量：600kg</td>
                <td>
                  <img src={Image13} className={Styles.media_title_img} alt='屋外型AGV' />
                  <a href='https://www.ipros.jp/product/detail/2000764796/' target="_blank" rel="nofollow noopener noreferrer">
                    屋外型AGV
                  </a>
                </td>
                <td>防水機能を搭載しており、雨の日の運用も可能  </td>
              </tr>
              <tr>
                <td>自動搬送車 (AGV) V1000-H<br />（株式会社KKS）</td>
                <td>サイズ：<br />1,200mm*900mm*320mm<br />速度：40m/分<br />搬送重量：1,000kg</td>
                <td>
                  <img src={Image14} className={Styles.media_title_img} alt='自動搬送車' />
                  <a href='https://metoree.com/products/52997/' target="_blank" rel="nofollow noopener noreferrer">
                    自動搬送車
                  </a>
                </td>
                <td>6％の傾斜やグレーチングなども走行が可能<br />「磁気誘導方式」「レーザー誘導方式」「ハイブリット制御方式」の3方式にカスタマイズ可能</td>
              </tr>
              <tr>
                <td>V800-JST<br />（株式会社KKS）</td>
                <td>サイズ：<br />L2,100mm × W1,500mm × H2,300mm (AGV本体：L1,400mm × W900mm × H450mm)<br />速度：最大40m/分<br />搬送重量：800kg</td>
                <td>
                  <img src={Image15} className={Styles.media_title_img} alt='V800-JST' />
                  <a href='https://metoree.com/products/52998/' target="_blank" rel="nofollow noopener noreferrer">
                    V800-JST
                  </a>
                </td>
                <td>シャッターによって雨風から製品を保護しながら搬送が可能<br />シャッターは自動開閉も可能</td>
              </tr>
            </tbody>
          </table>

          <h3 className={Styles.media_text_text_title}>6.コンベア型</h3><br />
          <table className={Styles.media_table}>
            <tbody>
              <tr>
                <th><strong>メーカー</strong></th>
                <th>性能</th>
                <th>画像</th>
                <th>特徴</th>
              </tr>
              <tr>
                <td><strong>自動移載搬送型AGV HRCシリーズ<br />（株式会社ヘッズ）</strong></td>
                <td>サイズ：<br />搬送重量によって変動<br />速度：60m/分<br />搬送重量：1t</td>
                <td>
                  <img src={Image16} className={Styles.media_title_img} alt='自動移載搬送型AGV' />
                  <a href='https://metoree.com/products/14241/' target="_blank" rel="nofollow noopener noreferrer">
                    自動移載搬送型AGV
                  </a>
                </td>
                <td>電動シリンダーで押し出すプッシャータイプ、チェーンコンベアタイプも対応<br />全方位走行も可能  </td>
              </tr>
              <tr>
                <td><strong>ローラーコンベヤ型AGV AGV-CV<br />（株式会社KKS）</strong></td>
                <td>サイズ：<br />(L) 1,100 × (W) 1,000 × (H) 590 (mm)<br />速度：最高30m/分<br />搬送重量：1t<br />連続走行時間：5時間</td>
                <td>
                  <img src={Image17} className={Styles.media_title_img} alt='ローラーコンベヤ型AGV' />
                  <a href='https://metoree.com/products/53001/' target="_blank" rel="nofollow noopener noreferrer">
                    ローラーコンベヤ型AGV
                  </a>
                </td>
                <td>ローラーコンベアを搭載しているため、製造ライン間の移動や製品ラック格納に適している</td>
              </tr>
              <tr>
                <td><strong>コンベヤ型AGV<br />（株式会社シコウ）</strong></td>
                <td>サイズ：<br />W700×L1330×H775<br />速度：最高50m/分<br />搬送重量：100kg</td>
                <td>
                  <img src={Image18} className={Styles.media_title_img} alt='コンベヤ型AGV' />
                  <a href='https://shiko.biz/works/%E8%A4%87%E6%95%B0%E3%83%A9%E3%82%A4%E3%83%B3%E3%81%AB%E9%83%A8%E5%93%81%E4%BE%9B%E7%B5%A6%E3%81%99%E3%82%8B%E3%82%B3%E3%83%B3%E3%83%99%E3%82%A2%E6%90%AD%E8%BC%89%E5%9E%8B%E7%84%A1%E4%BA%BA%E6%90%AC/' target="_blank" rel="nofollow noopener noreferrer">
                    コンベヤ型AGV
                  </a>
                </td>
                <td>25箇所からの個別要求に同時に対応可能</td>
              </tr>
            </tbody>
          </table>
          </>

          <>
          <div id='6' className={Styles.media_text_title}>
            <h2>6.まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            AGV（自動搬送車）は大きく①積載型、②低床型、③牽引型、④重量型、⑤屋外型、⑥コンベア型の6種類に分類することができ、事業の内容によって導入すべき型が異なってきます。<br />
            それぞれの型ごとに販売しているメーカーや商品も様々であり、サイズや速度、搬送重量に合わせて最適な機械を選択する必要があります。<br />
            <br />
            以下のwebサイトでは業種と業務領域に合わせて最適なAGVを検索することができ、メーカーごとの価格比較のために見積もりを取得することも可能となっています。<br />
            <br />
            自社の業務効率化、自動化のためにはどの型のAGVを導入すればいいのか、それにはいくらかかるのか、を以下のサイトにて比較、検討していただければと思います。<br />
            <br />
            参考文献<br />
            https://metoree.com/categories/5001/<br />
          </p>
          </>
          
        </div>
        
        {/* <Topic /> */}
        <div></div>
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
