import React from 'react';
import Styles from'./styles.module.css';
import Image from './../../../image/saveLabor/header/illustration.png';

const SaveLabor = () => {

  return (
    <>
    <div className={Styles.header}>
      <h3>省力化補助金へ掲載中の</h3>
      <h4>カタログを検索できるサイト</h4>
      <div>
        <img src={Image} />
      </div>
    </div>
    </>
  );
}

export default SaveLabor;
