import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Styles from'./styles.module.css';
import { FaChevronRight } from "react-icons/fa";
import Image1 from "./.../../../../../../image/saveLabor/industry/1.svg";
import Image2 from "./.../../../../../../image/saveLabor/industry/2.svg";
import Image3 from "./.../../../../../../image/saveLabor/industry/3.svg";
import Image4 from "./.../../../../../../image/saveLabor/industry/4.png";
import Image5 from "./.../../../../../../image/saveLabor/industry/5.png";
import Image6 from "./.../../../../../../image/saveLabor/industry/6.svg";
import Image7 from "./.../../../../../../image/saveLabor/industry/7.png";
import Image8 from "./.../../../../../../image/saveLabor/industry/8.svg";

const SaveLabor = () => {

  return (
    <>
    <h4 className={Styles.industry_title}>業種ごとに製品を探す</h4>
    <div className={Styles.industry}>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#FF9B04'}}>
          <img src={Image1} />
          <h4>共通</h4>
        </div>
        <Link to={`/search/industry=共通+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=共通+works=施設管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>施設管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=共通+works=人事・労務管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>人事・労務管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=共通+works=財務・経理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>財務・経理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#02A293'}}>
          <img src={Image2} />
          <h4>建設業</h4>
        </div>
        <Link to={`/search/industry=建設業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=建設業+works=資材調達+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>資材調達</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=建設業+works=調査・測量+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>調査・測量</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=建設業+works=施工+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>施工</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#F66232'}}>
          <img src={Image3} />
          <h4>製造業</h4>
        </div>
        <Link to={`/search/industry=製造業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=製造業+works=資材調達+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>資材調達</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=製造業+works=加工・生産+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>加工・生産</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=製造業+works=検査+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>検査</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#2295F2'}}>
          <img src={Image4} />
          <h4>倉庫業</h4>
        </div>
        <Link to={`/search/industry=倉庫業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=倉庫業+works=保管・在庫管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>保管・在庫管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=倉庫業+works=入出庫+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>入出庫</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=倉庫業+works=梱包・加工+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>梱包・加工</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#5266D2'}}>
          <img src={Image5} />
          <h4>卸売業</h4>
        </div>
        <Link to={`/search/industry=卸売業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=卸売業+works=仕入+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>仕入</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=卸売業+works=保管・在庫管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>保管・在庫管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=卸売業+works=入出庫+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>入出庫</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#84C13C'}}>
          <img src={Image6} />
          <h4>小売業</h4>
        </div>
        <Link to={`/search/industry=小売業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=小売業+works=仕入+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>仕入</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=小売業+works=保管・在庫管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>保管・在庫管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=小売業+works=店舗運営+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>店舗運営</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#775446'}}>
          <img src={Image7} />
          <h4>宿泊業</h4>
        </div>
        <Link to={`/search/industry=宿泊業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=宿泊業+works=受付案内+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>受付案内</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=宿泊業+works=予約管理+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>予約管理</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=宿泊業+works=客室清掃+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>客室清掃</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>
      <div>
        <div className={Styles.industry_contents_title} style={{backgroundColor : '#E9C403'}}>
          <img src={Image8} />
          <h4>飲食業</h4>
        </div>
        <Link to={`/search/industry=飲食業+works=+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>全て見る</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=飲食業+works=注文受付+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>注文受付</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=飲食業+works=配膳・下膳+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>配膳・下膳</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
        <Link to={`/search/industry=飲食業+works=請求・支払い+keyWord=+page=1`}>
          <div className={Styles.industry_contents_items}>
            <h5>請求・支払い</h5>
            <FaChevronRight className={Styles.industry_contents_icon} />
          </div>
        </Link>
      </div>

      <div className={Styles.decoy_1}></div>

    </div>
    </>
  );
}

export default SaveLabor;
