import Styles from'./styles.module.css';
import Image15 from "./../../../../image/saveLabor/work/15.svg";
import Image22 from "./../../../../image/saveLabor/work/22.svg";
import Image23 from "./../../../../image/saveLabor/work/23.svg";
import Image24 from "./../../../../image/saveLabor/work/24.svg";
import Image25 from "./../../../../image/saveLabor/work/25.svg";
import Image26 from "./../../../../image/saveLabor/work/26.svg";
import Image27 from "./../../../../image/saveLabor/work/27.svg";
import { useHistory } from 'react-router-dom';

const SaveLabor = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.catalog_all}>
      <div className={Styles.catalog_1} onClick={() => history.push(`/search/industry=飲食業+works=企画・営業+keyWord=+page=1`)}>
        <img src={Image22} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 企画・営業' />
        <h4>企画・営業</h4>
      </div>
      <div className={Styles.catalog_2} onClick={() => history.push(`/search/industry=飲食業+works=仕入+keyWord=+page=1`)}>
        <img src={Image23} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 仕入' />
        <h4>仕入</h4>
      </div>
      <div className={Styles.catalog_3} onClick={() => history.push(`/search/industry=飲食業+works=注文受付+keyWord=+page=1`)}>
        <img src={Image24} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 注文受付' />
        <h4>注文受付</h4>
      </div>
      <div className={Styles.catalog_4} onClick={() => history.push(`/search/industry=飲食業+works=調理+keyWord=+page=1`)}>
        <img src={Image25} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 調理' />
        <h4>調理</h4>
      </div>
      <div className={Styles.catalog_5} onClick={() => history.push(`/search/industry=飲食業+works=配膳・下膳+keyWord=+page=1`)}>
        <img src={Image26} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 配膳・下膳' />
        <h4>配膳・下膳</h4>
      </div>
      <div className={Styles.catalog_6} onClick={() => history.push(`/search/industry=飲食業+works=請求・支払+keyWord=+page=1`)}>
        <img src={Image15} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 請求・支払' />
        <h4>請求・支払</h4>
      </div>
      <div className={Styles.catalog_7} onClick={() => history.push(`/search/industry=飲食業+works=顧客対応+keyWord=+page=1`)}>
        <img src={Image27} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 顧客対応' />
        <h4>顧客対応</h4>
      </div>
      <div className={Styles.catalog_8}></div>
      <div className={Styles.catalog_9}></div>
      <div className={Styles.catalog_10}></div>
    </div>
    </>
  );
}

export default SaveLabor;
