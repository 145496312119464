import Styles from './styles.module.css';
import Icon from './../../../image/icon.png';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

const BlogText0001 = (props) => {

  const [ industry, setIndustry ] = useState('');
  const [ works, setWorks ] = useState('');

  useEffect(() => {
    if (props.industry !== '' && props.industry != undefined) {
      setIndustry(props.industry);
    }
    if (props.works !== '' && props.works != undefined) {
      setWorks(props.works);
    }
  }, []);

  return (
    <>
    <div className={Styles.nav}>

      <a href='/'>
        <div className={Styles.nav_title}>
          <img src={Icon} alt='中小企業省力化投資補助金' />
          <div>
            <h1>省力化補助金</h1>
            <h2>カタログ 検索サイト</h2>
          </div>
        </div>
      </a>

      <div className={Styles.nav_link}>

        {/* <h6>絞り込み</h6> */}

        <select value={industry} onChange={(e) => setIndustry(e.target.value)}>
          <option value="">業種</option>
          <option value="宿泊業">宿泊業</option>
          <option value="飲食業">飲食業</option>
          <option value="倉庫業">倉庫業</option>
          <option value="製造業">製造業</option>
          <option value="小売業">小売業</option>
        </select>

        <p>×</p>

        <select value={works} onChange={(e) => setWorks(e.target.value)}>
          <option value="">業務プロセス</option>
          <option value='施設管理'>施設管理</option>
          <option value='人事・労務管理'>人事・労務管理</option>
          <option value='財務・経理'>財務・経理</option>
          <option value='見積・契約'>見積・契約</option>
          <option value='資材調達'>資材調達</option>
          <option value='調査・測量'>調査・測量</option>
          <option value='施工'>施工</option>
          <option value='引渡'>引渡</option>
          <option value='加工・生産'>加工・生産</option>
          <option value='検査'>検査</option>
          <option value='販売・納品'>販売・納品</option>
          <option value='出荷'>出荷</option>
          <option value='入出庫'>入出庫</option>
          <option value='梱包・加工'>梱包・加工</option>
          <option value='請求・支払'>請求・支払</option>
          <option value='保管・在庫管理'>保管・在庫管理</option>
          <option value='店舗運営'>店舗運営</option>
          <option value='アフターサービス'>アフターサービス</option>
          <option value='受付案内'>受付案内</option>
          <option value='予約管理'>予約管理</option>
          <option value='客室清掃'>客室清掃</option>
          <option value='企画・営業'>企画・営業</option>
          <option value='仕入'>仕入</option>
          <option value='注文受付'>注文受付</option>
          <option value='調理'>調理</option>
          <option value='配膳・下膳'>配膳・下膳</option>
          <option value='顧客対応'>顧客対応</option>
          <option value='返品対応'>返品対応</option>
        </select>

        <p>=</p>

        <Link to={`/search/industry=${industry}+works=${works}+keyWord=+page=1`}>
          <button>検索</button>
        </Link>

      </div>
      
      <div className={Styles.search_box}>
        <FaSearch className={Styles.search_box_icon} />
      </div>

    </div>
    </>
  );
}

export default BlogText0001;
