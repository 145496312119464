import Styles from './styles.module.css';
import Icon from './../../../image/icon.png';
import { Link } from 'react-router-dom';

const Nav = () => {
  
  return (
    <>
    <div className={Styles.media_nav}>
      <Link to={`/`} className={Styles.media_nav_icon}>
        <img src={Icon} loading="lazy" alt='memorii' />
        <h2>業務効率化・省力化製品まとめ</h2>
      </Link>
      <a className={Styles.media_nav_contact} href='https://trankllc.com/contact'>
        <p>問い合わせ</p>
      </a>
    </div>
    </>
  );
}

export default Nav;
