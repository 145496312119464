import Styles from'./styles.module.css';
import Image1 from "./../../../../image/saveLabor/work/1.svg";
import Image2 from "./../../../../image/saveLabor/work/2.svg";
import Image3 from "./../../../../image/saveLabor/work/3.svg";
import Image4 from "./../../../../image/saveLabor/work/4.svg";
import Image5 from "./../../../../image/saveLabor/work/5.svg";
import Image6 from "./../../../../image/saveLabor/work/6.svg";
import Image7 from "./../../../../image/saveLabor/work/7.svg";
import Image8 from "./../../../../image/saveLabor/work/8.svg";
import Image9 from "./../../../../image/saveLabor/work/9.svg";
import Image10 from "./../../../../image/saveLabor/work/10.svg";
import Image11 from "./../../../../image/saveLabor/work/11.svg";
import Image12 from "./../../../../image/saveLabor/work/12.svg";
import Image13 from "./../../../../image/saveLabor/work/13.svg";
import Image14 from "./../../../../image/saveLabor/work/14.svg";
import Image15 from "./../../../../image/saveLabor/work/15.svg";
import Image16 from "./../../../../image/saveLabor/work/16.svg";
import Image17 from "./../../../../image/saveLabor/work/17.svg";
import Image18 from "./../../../../image/saveLabor/work/18.svg";
import Image19 from "./../../../../image/saveLabor/work/19.svg";
import Image20 from "./../../../../image/saveLabor/work/20.svg";
import Image21 from "./../../../../image/saveLabor/work/21.svg";
import Image22 from "./../../../../image/saveLabor/work/22.svg";
import Image23 from "./../../../../image/saveLabor/work/23.svg";
import Image24 from "./../../../../image/saveLabor/work/24.svg";
import Image25 from "./../../../../image/saveLabor/work/25.svg";
import Image26 from "./../../../../image/saveLabor/work/26.svg";
import Image27 from "./../../../../image/saveLabor/work/27.svg";
import Image28 from "./../../../../image/saveLabor/work/28.svg";
import { useHistory } from 'react-router-dom';

const SaveLabor = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.catalog_all}>
      <div className={Styles.catalog_1} onClick={() => history.push(`/search/industry=倉庫業+works=企画・営業+keyWord=+page=1`)}>
        <img src={Image22} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 企画・営業' />
        <h4>企画・営業</h4>
      </div>
      <div className={Styles.catalog_2} onClick={() => history.push(`/search/industry=倉庫業+works=見積・契約+keyWord=+page=1`)}>
        <img src={Image4} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 見積・契約' />
        <h4>見積・契約</h4>
      </div>
      <div className={Styles.catalog_3} onClick={() => history.push(`/search/industry=倉庫業+works=仕入+keyWord=+page=1`)}>
        <img src={Image23} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 仕入' />
        <h4>仕入</h4>
      </div>
      <div className={Styles.catalog_4} onClick={() => history.push(`/search/industry=倉庫業+works=保管・在庫管理+keyWord=+page=1`)}>
        <img src={Image16} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 保管・在庫管理' />
        <h4>保管・在庫管理</h4>
      </div>
      <div className={Styles.catalog_5} onClick={() => history.push(`/search/industry=倉庫業+works=入出庫+keyWord=+page=1`)}>
        <img src={Image13} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 入出庫' />
        <h4>入出庫</h4>
      </div>
      <div className={Styles.catalog_6} onClick={() => history.push(`/search/industry=倉庫業+works=梱包・加工+keyWord=+page=1`)}>
        <img src={Image14} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 梱包・加工' />
        <h4>梱包・加工</h4>
      </div>
      <div className={Styles.catalog_7} onClick={() => history.push(`/search/industry=倉庫業+works=出荷+keyWord=+page=1`)}>
        <img src={Image12} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 出荷' />
        <h4>出荷</h4>
      </div>
      <div className={Styles.catalog_8} onClick={() => history.push(`/search/industry=倉庫業+works=返品対応+keyWord=+page=1`)}>
        <img src={Image28} alt='中小企業省力化投資補助金の対象業務プロセス一覧 | 返品対応' />
        <h4>返品対応</h4>
      </div>
      <div className={Styles.catalog_9}></div>
      <div className={Styles.catalog_10}></div>
    </div>
    </>
  );
}

export default SaveLabor;
