import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

import SaveLabor from './component/1.home/index';
import SaveLaborSearch from './component/2.results/index';
import SaveLaborProduct from './component/3.product/index';
import Edit from './component/7.dataEdit/index';
import Contact from './component/contact/index';

import Media0000 from './component/8.media/index';
import Media0001 from './component/8.media/0001/index';
import Media0002 from './component/8.media/0002/index';
import Media0003 from './component/8.media/0003/index';
import Media0004 from './component/8.media/0004/index';
import Media0005 from './component/8.media/0005/index';
import Media0006 from './component/8.media/0006/index';
import Media0007 from './component/8.media/0007/index';
import Media0008 from './component/8.media/0008/index';
import Media0009 from './component/8.media/0009/index';
import Media0010 from './component/8.media/0010/index';
import Media0011 from './component/8.media/0011/index';
import Media0012 from './component/8.media/0012/index';
import Media0013 from './component/8.media/0013/index';
import Media0014 from './component/8.media/0014/index';
import Media0015 from './component/8.media/0015/index';
import Media0016 from './component/8.media/0016/index';
import Media0017 from './component/8.media/0017/index';
import Media0018 from './component/8.media/0018/index';
import Media0019 from './component/8.media/0019/index';
import Media0020 from './component/8.media/0020/index';
import Media0021 from './component/8.media/0021/index';
import Media0022 from './component/8.media/0022/index';

function App() {
  return (
    <>
    <BrowserRouter>

      <Route exact path="/" component={SaveLabor} />
      <Route exact path="/search/:industry" component={SaveLaborSearch} />
      <Route exact path="/product/:id" component={SaveLaborProduct} />
      <Route exact path="/edit/ngjakhdskdfsajlfdasml:asf" component={Edit} />
      <Route exact path="/contact/:id" component={Contact} />

      <Route exact path="/media/0000" component={Media0000} />
      <Route exact path="/media/0001" component={Media0001} />
      <Route exact path="/media/0002" component={Media0002} />
      <Route exact path="/media/0003" component={Media0003} />
      <Route exact path="/media/0004" component={Media0004} />
      <Route exact path="/media/0005" component={Media0005} />
      <Route exact path="/media/0006" component={Media0006} />
      <Route exact path="/media/0007" component={Media0007} />
      <Route exact path="/media/0008" component={Media0008} />
      <Route exact path="/media/0009" component={Media0009} />
      <Route exact path="/media/0010" component={Media0010} />
      <Route exact path="/media/0011" component={Media0011} />
      <Route exact path="/media/0012" component={Media0012} />
      <Route exact path="/media/0013" component={Media0013} />
      <Route exact path="/media/0014" component={Media0014} />
      <Route exact path="/media/0015" component={Media0015} />
      <Route exact path="/media/0016" component={Media0016} />
      <Route exact path="/media/0017" component={Media0017} />
      <Route exact path="/media/0018" component={Media0018} />
      <Route exact path="/media/0019" component={Media0019} />
      <Route exact path="/media/0020" component={Media0020} />
      <Route exact path="/media/0021" component={Media0021} />
      <Route exact path="/media/0022" component={Media0022} />

    </BrowserRouter>
    </>
  );
}

export default App;
