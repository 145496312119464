import React from 'react';
import Styles from'./styles.module.css';
import Image1 from './../../../image/media/0001/top.png';
import Image2 from './../../../image/media/0002/top.png';
import Image3 from './../../../image/media/0003/top.png';
import Image4 from './../../../image/media/0004/top.png';
import Image5 from './../../../image/media/0005/top.png';
import Image6 from './../../../image/media/0006/top.png';
import Image7 from './../../../image/media/0007/top.png';
import Image8 from './../../../image/media/0008/top.png';
import { Link } from 'react-router-dom';

const SaveLabor = () => {

  return (
    <>
    <div className={Styles.blog}>
      <h4>省人化・省力化に関する記事</h4>

      <div className={Styles.blog_contents}>
        <Link to={'/media/0001'}>
          <div>
            <img src={Image1} />
            <h5>2023年11月12日</h5>
            <h4>【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！</h4>
          </div>
        </Link>
        <Link to={'/media/0002'}>
          <div>
            <img src={Image2} />
            <h5>2023年11月12日</h5>
            <h4>中小企業省力化投資補助金の「カタログ」とは！？申請や方法について解説！</h4>
          </div>
        </Link>
        <Link to={'/media/0003'}>
          <div>
            <img src={Image3} />
            <h5>2023年11月12日</h5>
            <h4>自動精算機の導入価格をメーカー別に解説！（導入コスト & 製品比較）</h4>
          </div>
        </Link>
        <Link to={'/media/0004'}>
          <div>
            <img src={Image4} />
            <h5>2023年11月12日</h5>
            <h4>アルメックスの自動精算機６台の違いを比較！（業種別・機能別・特徴など）</h4>
          </div>
        </Link>
        <Link to={'/media/0005'}>
          <div>
            <img src={Image5} />
            <h5>2023年11月12日</h5>
            <h4>【2024年最新】中小企業省力化投資補助金の申請要件を徹底解説！</h4>
          </div>
        </Link>
        <Link to={'/media/0006'}>
          <div>
            <img src={Image6} />
            <h5>2023年11月12日</h5>
            <h4>中小企業省力化投資補助金の「カタログ」とは！？申請や方法について解説！</h4>
          </div>
        </Link>
        <Link to={'/media/0007'}>
          <div>
            <img src={Image7} />
            <h5>2023年11月12日</h5>
            <h4>自動精算機の導入価格をメーカー別に解説！（導入コスト & 製品比較）</h4>
          </div>
        </Link>
        <Link to={'/media/0008'}>
          <div>
            <img src={Image8} />
            <h5>2023年11月12日</h5>
            <h4>アルメックスの自動精算機６台の違いを比較！（業種別・機能別・特徴など）</h4>
          </div>
        </Link>
      </div>
    </div>
    </>
  );
}

export default SaveLabor;
