import DocumentMeta from 'react-document-meta';
import Styles from './../styles.module.css';
import Nav from "../nav/index.js";
import TopImage from "./../../../image/media/0020/top.png";
import Image1 from "./../../../image/media/0020/1.png";
import Image2 from "./../../../image/media/0020/2.png";
import Footer from '../footer/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AiFillCheckCircle } from "react-icons/ai";
import { useEffect } from "react";

const BlogText0001 = () => {

  const meta = {
    title: '無人搬送車（AGV）とは！？種類やメリット・デメリットをご紹介',
    description: '本記事は、「無人搬送車（AGV）の導入によって生産性の向上・省人化を図りたい」という方に向けて無人搬送車の種類やメリット・デメリットを解説させていただきます。効率化が求められる中で、様々な無人搬送車の種類がありどの種類を導入すべきか悩んでしまうと思われます。そこで、用途や使用場所もあわせてご説明したいと思いますので、ぜひ最後までご覧になっていただき、最適な無人搬送車を見つけていただければと思います。',
    canonical: 'https://shoryokuka.com/',
    meta: { charset: 'utf-8', }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <div className={Styles.media}>
        <div className={Styles.media_article}>

          <>
          <p className={Styles.media_date}>2024/05/20 更新日: 2024/06/05</p>
          <h1 className={Styles.media_title}>無人搬送車（AGV）とは！？種類やメリット・デメリットをご紹介</h1>
          <img src={TopImage} className={Styles.media_title_img} alt='事業再構築補助金' />
          </>

          <>
          <p className={Styles.media_text_text}>
            <br />
            本記事は、「無人搬送車（AGV）の導入によって生産性の向上・省人化を図りたい」という方に向けて無人搬送車の種類やメリット・デメリットを解説させていただきます。<br />
            効率化が求められる中で、様々な無人搬送車の種類がありどの種類を導入すべきか悩んでしまうと思われます。<br />
            そこで、用途や使用場所もあわせてご説明したいと思いますので、ぜひ最後までご覧になっていただき、最適な無人搬送車を見つけていただければと思います。<br />
          </p>
          </>

          <>
          <div className={Styles.media_introduction}>
            <div>
              <AiFillCheckCircle className={Styles.media_introduction_icon} />
              <h3>この記事でこの3点が分かる！</h3>
            </div>
            <hr></hr>
            <ul>
              <li><span>無人搬送車について</span></li>
              <li><span>無人搬送車の種類</span></li>
              <li><span>無人搬送車のメリット・デメリット</span></li>
            </ul>
          </div>
          </>

          <>
          <div className={Styles.media_contents}>
            <div className={Styles.media_contents_title}>
              本記事の目次
            </div>
            <div className={Styles.media_contents_contents}>
              <ol>
                <li><AnchorLink href="#1" offset="100"><strong>無人搬送車（AGV）とは？</strong></AnchorLink></li>
                <li><AnchorLink href="#2" offset="100"><strong>無人搬送車（AGV）の種類</strong></AnchorLink></li>
                <li><AnchorLink href="#3" offset="100"><strong>無人搬送車（AGV）のメリット</strong></AnchorLink></li>
                <li><AnchorLink href="#4" offset="100"><strong>無人搬送車（AGV）のデメリット</strong></AnchorLink></li>
                <li><AnchorLink href="#5" offset="100"><strong>まとめ</strong></AnchorLink></li>
              </ol>
            </div>
          </div>
          </>

          <>
          <div id='1' className={Styles.media_text_title}>
            <h2>1. 無人搬送車（AGV）とは？</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            無人搬送車（AGV）とは、「Automatic Guided Vehicle」いわゆる、人の代わりに荷物の搬送を行うロボットです。搬送ロボットと聞くと未来的な技術と想像しますが、AGVを導入する企業が増えています。また、導入により生産性の向上・安全性の向上・コスト削減が見込めます。<br />
            <br />
            <img src={Image1} className={Styles.media_title_img} alt='無人搬送車（AGV）' />
            引用：<a href='https://jss1.jp/column/column_314/' target="_blank" rel="nofollow noopener noreferrer">
              無人搬送車（AGV）
            </a>
          </p>
          <h3 className={Styles.media_text_text_title}>①注目を集める無人搬送車（AGV）</h3><br />
          <p className={Styles.media_text_text}>
            「Automatic Guided Vehicle」いわゆる、人の代わりに荷物の搬送を行うロボットです。搬送ロボットと聞くと未来的な技術と想像しますが、AGVを導入する企業が増えています。また、導入により生産性の向上・安全性の向上・コスト削減が見込めます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②製造業だけでなく、幅広い現場に拡大</h3><br />
          <p className={Styles.media_text_text}>
            多くの人手を必要とする工場や大型倉庫だけでなく、病院や飲食店でも無人搬送車を目にするようになりました。<br />
            人件費の高騰や労働力不足が深刻化する中で、無人搬送車は画期的な解決策として大きな注目を浴びています。<br />
          </p>
          </>

          <>
          <div id='2' className={Styles.media_text_title}>
            <h2>2. 無人搬送車（AGV）の種類</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            本項目では無人搬送車の種類について解説していきます。物流倉庫や製造工場などで導入が進んでいますが、種類が多くて導入の際に迷われる方も多いと思います。<br />
            それぞれの特徴を理解しておくことが大切なので、違いもあわせて紹介していきます。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
            <strong>① 積載型</strong><br />
            <strong>② 低床型</strong><br />
            <strong>③ 牽引型</strong><br />
            <strong>④ 経路誘導式</strong><br />
            <strong>⑤ 自律移動式</strong><br />
            <strong>⑥ 追従式</strong><br />
            <strong>⑦ 重量型</strong><br />
            <strong>⑧ 屋外型</strong><br />
            <strong>⑨ 特殊型</strong><br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>①積載型</h3><br />
          <p className={Styles.media_text_text}>
            台車に荷物を運んで搬送するタイプです。主に段ボールやコンテナなどの荷物を運ぶ際に適していて、倉庫などでの活用が多いです。人が搬送物を積載する方式と、搬送機械と連携して自動で搬送物を積載する方式があります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②低床型</h3><br />
          <p className={Styles.media_text_text}>
            荷物が載っている棚の下に潜り込んで搬送するタイプです。搬送物を積み替える必要がないので、より効率よく搬送することが可能です。低床型は床面からの高さが非常に低いため、低い場所や狭い場所での作業に優れています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③牽引型</h3><br />
          <p className={Styles.media_text_text}>
            荷物を載せた台車を牽引して搬送するタイプです。牽引型は一度に大量の荷物を搬送可能で、数十トン以上の荷物を搬送できるので幅広い場所で活躍できます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>④経路誘導式</h3><br />
          <p className={Styles.media_text_text}>
            磁気テープや電磁誘導ケーブルを利用して走行ルートを決め、その上を搬送するタイプです。ルートが高機能なセンサーによって記憶されているため、直進や停止が正確です。床にテープが貼れない場所や、ルートが頻繁に変更される場所での使用は控えた方がよいかもしれません。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑤自律移動式</h3><br />
          <p className={Styles.media_text_text}>
            事前に走行ルートや目的地を記憶させると、自分の位置を推定して走行を制御しながら搬送するタイプです。床にテープが貼れない場所や、近年では飲食店で多く導入されています。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑥追従式</h3><br />
          <p className={Styles.media_text_text}>
            先行する車両や人と一定の距離を保って移動するタイプです。事前に記憶させる必要がないので、柔軟な搬送が求められる場所で最適なタイプとなります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑦重量型</h3><br />
          <p className={Styles.media_text_text}>
            数十トン、数百トンの荷物を搬送が可能なタイプです。重量型は搬送物の重さに応じて選ぶことができ、種類も豊富なので使用用途に合わせて最適なタイプを選べます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑧屋外型</h3><br />
          <p className={Styles.media_text_text}>
            整備された路面だけでなく、屋外の凹凸が激しい路面でも荷物を搬送できるタイプです。ほかにも、屋外型は雨天にも対応しているので故障などを気にすることなく作業できます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>⑨特殊型</h3><br />
          <p className={Styles.media_text_text}>
            使用用途に合わせてオーダーメイドやカスタマイズできるタイプです。たとえば、屋外で使うので屋根を付けるなどのカスタマイズが可能です。カスタマイズの方法は豊富なので最適な搬送車が見つかりますが、コストもかかってしまうので十分に検討する必要があります。<br />
            <img src={Image2} className={Styles.media_title_img} alt='自動倉庫 縦型シャトル倉庫' />
          </p>
          </>

          <>
          <div id='3' className={Styles.media_text_title}>
            <h2>3. 無人搬送車（AGV）のメリット</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            本項目では無人搬送車の導入にあたって、メリットについて解説していきます。日本国内では1980年代から工場などで導入され、近年では飲食店でも多く活用されています。無人搬送車にはいったいどんなメリットがあるか紹介していきます。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              <strong>①省人化</strong><br />
              <strong>②安全性の向上</strong><br />
              <strong>③生産性の可視化</strong><br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>①省人化</h3><br />
          <p className={Styles.media_text_text}>
            従来は人間が荷物の搬送を行っていましたが、搬送車が人間の代わりに行うと、搬送に携わっていた人はほかの仕事に取り組むことができ、負担軽減が実現可能になります。また、搬送車が一度に運ぶ量が多いため、搬送の回数も大幅に減らすことができます。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②安全性の向上</h3><br />
          <p className={Styles.media_text_text}>
            人が大きな荷物を搬送すると誤って怪我につながってしまったり、疲労から大きな事故につながってしまいますが、機械は安全性の心配は必要ありません。様々なリスクを回避することが可能になります。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>③生産性の可視化</h3><br />
          <p className={Styles.media_text_text}>
            無人搬送車の種類によっては、ネットワークと接続すると搬送状況をリアルタイムで把握できます。業務の修正や計画も瞬時に軌道修正可能なので生産性の可視化もより簡単になります。<br />
          </p>
          </>

          <>
          <div id='4' className={Styles.media_text_title}>
            <h2>4. 無人搬送車（AGV）のデメリット</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            本項目では無人搬送車の導入することによるデメリットについて解説していきます。前項でメリットについて紹介しましたが、どんなデメリットがあって、注意すべき点などを詳しく見ていきましょう。<br />
          </p>
          <div className={Styles.media_introduction}>
            <p>
              <strong>①導入コストがかかる</strong><br />
              <strong>②事前の準備や手間がかかる</strong><br />
            </p>
          </div>
          <h3 className={Styles.media_text_text_title}>①導入コストがかかる</h3><br />
          <p className={Styles.media_text_text}>
            導入するとなるともちろん費用がかかります。大きな現場となると、複数台搬送車が必要となります。導入した時の費用対効果を十分に考慮した上で導入しなければなりません。<br />
          </p>
          <h3 className={Styles.media_text_text_title}>②事前の準備や手間がかかる</h3><br />
          <p className={Styles.media_text_text}>
            導入する搬送車のタイプによりますが、搬送車を走行するためにルートや誘導体が必要となります。そのほかにも、現場の棚などの配置の再構成を要する場合もあるので気をつけておきましょう。<br />
          </p>
          </>

          <>
          <div id='5' className={Styles.media_text_title}>
            <h2>5. まとめ</h2>
          </div>
          <p className={Styles.media_text_text}>
            <br />
            本記事は、無人搬送車の導入を検討している方に向けてメリット・デメリットを説明させていただきました。説明の中でもあった通り、導入には多額の費用がかかるため省力化補助金を利用して導入することをご提案させていただいております。<br />
            <br />
            省力化補助金についてもっと知りたい、利用して導入したいという方は弊社にぜひともご相談下さいませ。ご相談は右上のお問い合わせよりお待ちしております。<br />
            <br />
            無人搬送車には様々な種類があるため、導入時現場に適したものを選ぶ際に悩まれる方が多いので少しでもお役に立てればと考えております。<br />
            <br />
            この度は最後まで記事をお読みいただき、誠にありがとうございました。<br />
          </p>
          </>
          
        </div>
        
        {/* <Topic /> */}
        <div></div>
      </div>

      <Footer />
    </DocumentMeta>
    </>
  );
}

export default BlogText0001;
