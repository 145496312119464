import Styles from './styles.module.css';
import { Link } from 'react-router-dom';

const Search = (props) => {

  return (
    <>
    <div className={Styles.box}>
      <p>業種</p>
      <select value={props.industry} onChange={(e) => props.setIndustry(e.target.value)}>
        <option value="">未選択</option>
        <option value="宿泊業">宿泊業</option>
        <option value="飲食業">飲食業</option>
        <option value="倉庫業">倉庫業</option>
        <option value="製造業">製造業</option>
        <option value="宿泊業">宿泊業</option>
        <option value="小売業">小売業</option>
      </select>
      <p>業務領域</p>
      <select value={props.works} onChange={(e) => props.setWorks(e.target.value)}>
        <option value="">未選択</option>
        <option value='施設管理'>施設管理</option>
        <option value='人事・労務管理'>人事・労務管理</option>
        <option value='財務・経理'>財務・経理</option>
        <option value='見積・契約'>見積・契約</option>
        <option value='資材調達'>資材調達</option>
        <option value='調査・測量'>調査・測量</option>
        <option value='施工'>施工</option>
        <option value='引渡'>引渡</option>
        <option value='加工・生産'>加工・生産</option>
        <option value='検査'>検査</option>
        <option value='販売・納品'>販売・納品</option>
        <option value='出荷'>出荷</option>
        <option value='入出庫'>入出庫</option>
        <option value='梱包・加工'>梱包・加工</option>
        <option value='請求・支払'>請求・支払</option>
        <option value='保管・在庫管理'>保管・在庫管理</option>
        <option value='店舗運営'>店舗運営</option>
        <option value='アフターサービス'>アフターサービス</option>
        <option value='受付案内'>受付案内</option>
        <option value='予約管理'>予約管理</option>
        <option value='客室清掃'>客室清掃</option>
        <option value='企画・営業'>企画・営業</option>
        <option value='仕入'>仕入</option>
        <option value='注文受付'>注文受付</option>
        <option value='調理'>調理</option>
        <option value='配膳・下膳'>配膳・下膳</option>
        <option value='顧客対応'>顧客対応</option>
        <option value='返品対応'>返品対応</option>
      </select>
      <p>キーワード</p>
      <input placeholder='会社名、設備名など' 
        value={props.keyWord} onChange={(e) => props.setKeyWord(e.target.value)} />
      <Link to={`/search/industry=${props.industry}+works=${props.works}+keyWord=${props.keyWord}+page=1`}>
        <button>検索</button>
      </Link>
    </div>
    </>
  );
}

export default Search;
